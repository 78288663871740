import React, { useState } from 'react';

export const ToggleContext = React.createContext();

export const ToggleProvider = ({ children }) => {
    const [toggle, setToggle] = useState(false);
    console.log(toggle, "TOGGLE ")
    return (
        <ToggleContext.Provider value={{ toggle, setToggle }}>
            {children}
        </ToggleContext.Provider>
    );
};