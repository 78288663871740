export const getMintData = async (setMintData, setIsLoading) => {
  const mintData = await fetch(
    "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/stability_pool"
  );
  const data = await mintData.json();
  const updatedMintData = [
    {
      icon: "/assets/yields/telos-evm.svg",
      title: "Stability Pool",
      subTitle: "TelosEVM",
      percentage: `${data.telos}%`,
      color: "#8273FF",
      appPath: "https://mint.meridianfinance.net/#/",
    },
    {
      icon: "/assets/yields/fuse.svg",
      title: "Stability Pool",
      subTitle: "Fuse",
      percentage: `${data.fuse}%`,
      color: "#28C76F",
      appPath: "https://mint.meridianfinance.net/#/",
    },
    {
      icon: "/assets/yields/base.svg",
      title: "Stability Pool",
      subTitle: "Base",
      percentage: `${data.base}%`,
      color: "#60C3FB",
      appPath: "https://mint.meridianfinance.net/#/",
    },
  ];

  // Filter out items with percentage of '0%'
  const nonZeroMintData = updatedMintData.filter(
    (item) => parseFloat(item.percentage) > 0
  );

  // Sort data by percentage descending
  nonZeroMintData.sort(
    (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
  );

  setMintData(nonZeroMintData);
  setIsLoading(false);
};
