import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './PageSwitcher.css'; // Import the CSS file

const PageSwitcher = () => {
  const location = useLocation(); // Get the current location
  const [activePage, setActivePage] = useState(location.pathname); // Initialize activePage state with current path

  const handlePageChange = (path: string) => {
    setActivePage(path); // Update activePage state when a button is clicked
  };

  return (
    <div className="page-switcher-container">
        <div className="page-switcher">
        <Link to="/stakemst" className={`page-switcher-button ${activePage === '/stakemst' ? 'active' : ''}`} onClick={() => handlePageChange('/stakemst')}>
            Staking
        </Link>
        <Link to="/yields" className={`page-switcher-button ${activePage === '/yields' ? 'active' : ''}`} onClick={() => handlePageChange('/yields')}>
            Analytics
        </Link>
        <Link to="/tokens" className={`page-switcher-button ${activePage === '/tokens' ? 'active' : ''}`} onClick={() => handlePageChange('/tokens')}>
            Token Portal
        </Link>
        <Link to="/ecosystem" className={`page-switcher-button ${activePage === '/ecosystem' ? 'active' : ''}`} onClick={() => handlePageChange('/ecosystem')}>
            Ecosystem
        </Link>
        </div>
    </div>
  );
};

export default PageSwitcher;

