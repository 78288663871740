import React, { useState, useEffect, useMemo, useRef } from "react";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";

import Modal from "components/Modal/Modal";
import Tooltip from "components/Tooltip/Tooltip";
import Footer from "components/Footer/Footer";
import TitleSection from "../../components/TitleSection/TitleSection";

import MSTStaking from "abis/MSTStaking.json";
import USDMStaking from "abis/USDMStaking.json";
import Token from "abis/Token.json";
import RewardReader from "abis/RewardReader.json";
import fetchPrice from "./FetchPrice";

import { ethers, BigNumber, utils } from "ethers";
import {
  MST_DECIMALS,
  NATIVE_DECIMALS,
  USD_DECIMALS,
  DIA_DECIMALS,
  SECONDS_PER_DAY,
  BASIS_POINTS_DIVISOR,
  PLACEHOLDER_ACCOUNT,
  getProcessedData,
  getPageTitle,
  getStakingData,
} from "lib/legacy";
import { useMstPrice, useTotalMstStaked, useTotalMstSupply } from "domain/legacy";
import { TELOS_MAINNET, BASE_MAINNET, FUSE_MAINNET, METER_MAINNET, TAIKO_MAINNET, NETWORK_METADATA, getRpcUrl, getChainName, getConstant } from "config/chains";

import useSWR from "swr";

import { getContract } from "config/contracts";
import { getProvider } from "lib/rpc";
import { switchNetwork } from "lib/wallets";
import SwitchNetworkModal from "components/SwitchNetworkModal/SwitchNetworkModal";

import "./StakeMST.css";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { getServerUrl } from "config/backend";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { approveTokens } from "domain/tokens";
import { TOKEN_PRICE_ENDPOINTS, NETWORK_TOKEN_ENDPOINTS } from "config/tokenPriceEndpoints"
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, formatKeyAmount, parseValue } from "lib/numbers";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getDeltas } from './USDMStakingRateGetter'
import InterestRate from "./InterestRateGetter"

const { AddressZero } = ethers.constants;

// Helper functions

const getRateFromMultiplier = (multiplier, estimatedBaseApr) => {
  const rateValue = BigNumber.from(multiplier).mul(estimatedBaseApr);
  return rateValue
}

function calculateDaysFromNow(timestamp) {
  const now = Date.now() / 1000; // Current timestamp in seconds
  const differenceInSeconds = timestamp - now;
  if (differenceInSeconds <= 0) {
    return 0;
  }
  return Math.ceil(differenceInSeconds / (24 * 60 * 60));
}

function daysFromNowString(timestamp) {
  const daysFromNow = calculateDaysFromNow(timestamp)
  if (daysFromNow <= 0) {
    return 'now';
  }
  return `${daysFromNow} days`;
}

function GetMaxAvailableToRedeem(userInvestments) {
  if (!userInvestments) {
    return 0
  }
  let maxAvailableToRedeem = BigNumber.from(0);
  userInvestments.forEach((pool) => {
    pool.forEach((entry) => {
      const { amount, vestingTimestamp } = entry;
      const timespan = calculateDaysFromNow(vestingTimestamp);
      if (timespan <= 0) {
        maxAvailableToRedeem = maxAvailableToRedeem.add(BigNumber.from(amount.toString()));
      }
    });
  });
  return maxAvailableToRedeem;
}

// Main components

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    stakingContractAddress,
    stakeMethodName,
    setPendingTxns,
    presetDurations,
    presetMultipliers,
    estimatedBaseApr,
    rewardsAvailable,
  } = props;

  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const { data: tokenAllowance } = useSWR(
    active && stakingTokenAddress && [active, chainId, stakingTokenAddress, "allowance", account, farmAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return t`Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
    if (selectedRowIndex === null) {
      return t`Select lockup period`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(stakingContractAddress, MSTStaking.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount, selectedRowIndex], {
      sentMsg: t`Stake submitted!`,
      failMsg: t`Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
        setSelectedRowIndex(null)
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    if (selectedRowIndex === null) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return t`Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return t`Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return t`Staking...`;
    }
    return t`Stake`;
  };

  const AprTableInModal = ({ presetMultipliers, presetDurations, estimatedBaseApr }) => {
    if (!presetMultipliers || !presetDurations) {
      return <div>Loading ...</div>;
    }
    const daysArray = presetDurations.map((value) => `${value} ${value === 1 ? 'Day' : 'Days'}`);

    const handleCheckboxChange = (index) => {
      setSelectedRowIndex(index);
    };


    return (
      <div className="In-modal-card">
        <div className="In-modal-card-title">
          <Trans>Available Rates</Trans>
        </div>
        <div className="In-modal-card-content">
          <div className="In-modal-card-row" >
            <div className="In-modal-card-table-headers">Min Lockup</div>
            <div className="In-modal-card-table-headers">Forecast APR</div>
            <div className="In-modal-card-table-headers">Select</div>
          </div>
          {presetMultipliers.map((value, index) => (
            <div className="In-modal-card-row" key={index}>
              <div>
                {daysArray[index]}
              </div>
              <div>
                {formatAmount(getRateFromMultiplier(value, estimatedBaseApr), 19, 1, false)}%
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={selectedRowIndex === index}
                  onChange={() => handleCheckboxChange(index)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          {(() => AprTableInModal({ presetMultipliers, presetDurations, estimatedBaseApr }))()}
          <div className="Modal-card">
            <div className="Modal-card-padding">
              <div className="Modal-card-headers">
                <div className="Stake-card-top-title-section">
                  <Trans>Stake</Trans>
                </div>
                <div className="Stake-card-top-title-section align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
                  <Trans>Max: {formatAmount(maxAmount, 18, 2, true)}</Trans>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div>
                  <input
                    type="number"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </div>
                <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
              </div>
            </div>
          </div>
        </div>
        {rewardsAvailable && (
          <div className="Message_box_small_font">
            Pending rewards will be sent to your wallet
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    stakingContractAddress,
    unstakeMethodName,
    setPendingTxns,
    rewardsAvailable,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);

  const getError = () => {
    if (!amount) {
      return t`Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(stakingContractAddress, MSTStaking.abi, library.getSigner());

    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: t`Unstake submitted!`,
      failMsg: t`Unstake failed.`,
      successMsg: t`Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return t`Unstaking...`;
    }
    return t`Unstake`;
  };

  return (
    <div className="Modal-card">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Modal-card">
          <div className="Modal-card-padding ">
            <div className="Modal-card-headers">
              <div className="Stake-card-top-title-section">
                <Trans>Unstake</Trans>
              </div>
              <div className="Stake-card-top-title-section align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
                <Trans>Max: {formatAmount(maxAmount, 18, 2, true)}</Trans>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">{unstakingTokenSymbol}</div>
            </div>
          </div>
        </div>
        {rewardsAvailable && (
          <div className="Message_box_small_font">
            Pending rewards will be sent to your wallet
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}


function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    stakingContractAddress,
    library,
    chainId,
    setPendingTxns,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);

  const isPrimaryEnabled = () => {
    return !isClaiming;
  };

  const getPrimaryText = () => {
    if (isClaiming) {
      return t`Claiming...`;
    }
    return t`Claim`;
  };

  const onClickPrimary = () => {
    setIsClaiming(true);

    const contract = new ethers.Contract(stakingContractAddress, MSTStaking.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "claimRewards",
      {
        sentMsg: t`Claim submitted.`,
        failMsg: t`Claim failed.`,
        successMsg: t`Claim completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsClaiming(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Claim Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Trans>
              Claim all reward tokens to wallet
            </Trans>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default function StakeV2({ setPendingTxns, connectWallet }) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const chainName = getChainName(chainId);

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");
  const [estimatedBaseApr, setEstimatedBaseApr] = useState(BigNumber.from(0));

  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);
  const [isSwitchNetworkVisible, setIsSwitchNetworkVisible] = useState(false);
  const [targetChainId, setTargetChainId] = useState(TELOS_MAINNET)
  const [targetPath, setTargetPath] = useState("https://mint.meridianfinance.net/#/")

  const [mstMarketCap, setMstMarketCap] = useState(0);
  const [tokenSymbols, setRewardTokenSymbols] = useState([]);

  const [totalUserInvestment, setTotalUserInvestment] = useState(BigNumber.from(0));
  const [availableToRedeem, setAvailableToRedeem] = useState(BigNumber.from(0));
  const [totalTokensToUsd, setTotalTokensToUsd] = useState(BigNumber.from(0));
  const [averageWeightedApr, setAverageWeightedApr] = useState(BigNumber.from(0));
  const [tokensInUsd, setTokensInUsd] = useState(BigNumber.from(0));
  const [tokenPrices, setTokenPrices] = useState([]);
  const [usdmStakingAprTlos, setUsdmStakingAprTlos] = useState(BigNumber.from(0));
  const [usdmStakingAprBase, setUsdmStakingAprBase] = useState(BigNumber.from(0));
  const [networkTokenPrices, setNetworkTokenPrices] = useState({});
  const [stakingData, setStakingData] = useState({});
  const [aprTimer, setAprTimer] = useState(0);
  const [otherChainAprData, setOtherChainAprData] = useState([]);

  const mstAddress = getContract(chainId, "MST");
  const rewardsAvailable = totalTokensToUsd.gt(0);

  const MeridianMultiPoolStakingAddress = getContract(chainId, "MeridianMultiPoolStaking");
  const stakingContractAddress = MeridianMultiPoolStakingAddress

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const { total: mstPrice } = useMstPrice()
  const mstPriceRef = useRef(mstPrice);

  const EthPrice = networkTokenPrices.hasOwnProperty('TLOS') ? BigNumber.from(networkTokenPrices['ETH'].toString()) : BigNumber.from(0)

  const handleNetworkCheck = (target, path) => {
    setTargetChainId(target);
    setTargetPath(path);
    setIsSwitchNetworkVisible(true);
  };

  const handleRedirect = (target) => {
    window.location.href = target;
  }

  // Contract Calls and data setup functions

  //get user wallet balance of MST
  const { data: walletMSTBalance } = useSWR(
    [`StakeMST:walletMSTBalance:${active}`, chainId, getContract(chainId, "MST"), "balanceOf", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  //get user staking balance for MST
  const { data: userTotalMstStaked } = useSWR(
    [`StakeMST:userTotalMstStaked:${active}`, chainId, MeridianMultiPoolStakingAddress, "getMstStake", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, MSTStaking),
    }
  );
  // get the user's pending rewards
  const { data: userPendingRewards } = useSWR(
    [`StakeMST:userPendingRewards:${active}`, chainId, MeridianMultiPoolStakingAddress, "getUserPendingRewards", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, MSTStaking),
    }
  );
  // fetch the configured reward token addresses
  const { data: rewardTokens } = useSWR(
    [`StakeMST:rewardTokens:${active}`, chainId, MeridianMultiPoolStakingAddress, "getRewardTokens"],
    {
      fetcher: contractFetcher(library, MSTStaking),
      refreshInterval: 60000, // 60 second refresh
    }
  );
  // fetch the configured number of investment pools
  const { data: numberOfPools } = useSWR(
    [`StakeMST:numberOfPools:${active}`, chainId, MeridianMultiPoolStakingAddress, "numberOfPools"],
    {
      fetcher: contractFetcher(library, MSTStaking),
      refreshInterval: 60000, // 60 second refresh
    }
  );

  // Get the oracle price for the network tokens
  const networkTokenEndPoints = Object.values(NETWORK_TOKEN_ENDPOINTS).map(token => token.PriceEndPoint);
  const { data: nativeTokenPrices, networkTokenError } = useSWR(
    networkTokenEndPoints ? networkTokenEndPoints.map(endpoint => endpoint) : null, (...endpoints) => Promise.all(endpoints.map((endpoint) => fetchPrice(endpoint))),
    {
      fallbackData: [],
    });
  if (networkTokenError) {
    console.error("Error fetching Network Token Prices:", networkTokenError);
  }
  useEffect(() => {
    if (nativeTokenPrices) {
      // create a price object eg{TLOS: TLOSprice, ETH: ETHprice ... }
      const formattedData = nativeTokenPrices.reduce((acc, price, index) => {
        const networkTokenSymbol = Object.values(NETWORK_TOKEN_ENDPOINTS).map(token => token.Symbol);
        const tokenName = networkTokenSymbol[index];
        acc[tokenName] = price;
        return acc;
      }, {});
      setNetworkTokenPrices(formattedData);
    }
  }, [nativeTokenPrices]);

  // Get the oracle price for the reward tokens
  const priceEndPoints = Array.isArray(rewardTokens) ? rewardTokens.map((address) => TOKEN_PRICE_ENDPOINTS[address]?.PriceEndPoint) : [];
  const { data: prices, diaError } = useSWR(
    priceEndPoints ? priceEndPoints.map(endpoint => endpoint) : null, (...endpoints) => Promise.all(endpoints.map((endpoint) => fetchPrice(endpoint))),
    {
      fallbackData: [],
      refreshInterval: 30000, // 30 second refresh
    });
  if (diaError) {
    console.error("Error fetching reward token Prices:", diaError);
  }

  useEffect(() => {
    setTokenPrices(prices ? prices : []);
  }, [prices]);

  // Set reward token prices in USD
  const getTotalTokensToUsd = (userPendingRewards, tokenPrices) => {
    if (userPendingRewards && tokenPrices) {
      return userPendingRewards.reduce((sum, value, index) => {
        const tokenToUsd = getTokensToUsd(index, value, tokenPrices);
        return sum.add(tokenToUsd);
      }, BigNumber.from(0));
    } else {
      return BigNumber.from(0)
    }
  };
  useEffect(() => {
    if (userPendingRewards && tokenPrices) {
      let resultArray = [];
      userPendingRewards.forEach((numTokens, index) => {
        const thisTokenUSDValue = getTokensToUsd(index, numTokens, tokenPrices);
        resultArray.push(thisTokenUSDValue);
      });
      setTokensInUsd(resultArray);
    }
  }, [userPendingRewards, tokenPrices]);

  useEffect(() => {
    setTotalTokensToUsd(getTotalTokensToUsd(userPendingRewards, tokenPrices));
  }, [userPendingRewards, tokenPrices]);


  // get reward token symbols for display
  useEffect(() => {
    if (rewardTokens) {
      const symbols = rewardTokens.map((address) => TOKEN_PRICE_ENDPOINTS[address]?.Symbol);
      setRewardTokenSymbols(symbols);
    }
  }, [rewardTokens]);

  useEffect(() => {
    mstPriceRef.current = mstPrice; // Update mstPriceRef when mstPrice changes
  }, [mstPrice]);

  // Set the estimated APR for each network
  const fetchTlosAPR = async () => {
    try {
      const apr = await InterestRate({ chainId: TELOS_MAINNET, mstPrice: mstPriceRef.current });
      return apr
    } catch (error) { console.error('Error fetching TLOS APR:', error); }
  }

  const fetchBaseAPR = async () => {
    try {
      const apr = await InterestRate({ chainId: BASE_MAINNET, mstPrice: mstPriceRef.current });
      return apr
    } catch (error) { console.error('Error fetching BASE APR:', error); }
  }

  const fetchFuseAPR = async () => {
    try {
      const apr = await InterestRate({ chainId: FUSE_MAINNET, mstPrice: mstPriceRef.current });
      console.log(apr, "Meter ARR");
      return apr
    } catch (error) { console.error('Error fetching FUSE APR:', error); }
  }

  const fetchMeterAPR = async () => {
    try {
      const apr = await InterestRate({ chainId: METER_MAINNET, mstPrice: mstPriceRef.current });
      return apr
    } catch (error) { console.error('Error fetching NETER APR:', error); }
  }

  const fetchTaikoAPR = async () => {
    try {
      const apr = await InterestRate({ chainId: TAIKO_MAINNET, mstPrice: mstPriceRef.current });
      console.log(apr, "ARR");
      return apr
    } catch (error) { console.error('Error fetching TAIKO APR:', error); }
  }

  // selector to get the base APR for specified chainId
  const getCurrentBaseApr = (tlosAPR, baseAPR, fuseAPR, meterAPR, taikoAPR, chainId) => {
    switch (chainId) {
      case TELOS_MAINNET:
        return tlosAPR ? tlosAPR : BigNumber.from(0);
      case BASE_MAINNET:
        return baseAPR ? baseAPR : BigNumber.from(0);
      case FUSE_MAINNET:
        return fuseAPR ? fuseAPR : BigNumber.from(0);
      case METER_MAINNET:
        return meterAPR ? meterAPR : BigNumber.from(0);
      case TAIKO_MAINNET:
        return taikoAPR ? taikoAPR : BigNumber.from(0);
      default:
        return BigNumber.from(0);
    }
  };

  // Initiate calculations for current base APR
  useEffect(() => {
    const fetchDataAndCalculateAPR = async () => {
      try {
        const [tlosAPR, baseAPR, fuseAPR, meterAPR, taikoAPR] = await Promise.all([fetchTlosAPR(), fetchBaseAPR(), fetchFuseAPR(), fetchMeterAPR(), fetchTaikoAPR()]);
        const latestAPR = getCurrentBaseApr(tlosAPR, baseAPR, fuseAPR, meterAPR, taikoAPR, chainId);
        setEstimatedBaseApr(latestAPR);

        // Generate array of other chainIds and their respective APRs
        const networks = [
          { id: TELOS_MAINNET, apr: tlosAPR },
          { id: BASE_MAINNET, apr: baseAPR },
          { id: FUSE_MAINNET, apr: fuseAPR },
          { id: METER_MAINNET, apr: meterAPR },
          { id: TAIKO_MAINNET, apr: taikoAPR }

        ];

        console.log(taikoAPR, "taikoAPR")

        const filteredNetworks = networks.filter(network => network.id !== chainId);
        const otherChainData = filteredNetworks.map(network => ({
          targetChainId: network.id,
          currentBaseStakingApr: network.apr
        }));
        setOtherChainAprData(otherChainData);

      } catch (error) {
        console.error("Error fetching and calculating APR:", error);
      }
    };
    fetchDataAndCalculateAPR();
    const intervalId = setInterval(() => { setAprTimer(prevTimer => prevTimer + 1); }, 10000); // 10 seconds
    return () => clearInterval(intervalId);
  }, [chainId, aprTimer]);



  // get the super staking data for trading platform (Base Network)
  const rewardReaderAddress = getContract(BASE_MAINNET, 'RewardReader');
  const stakedGmxTrackerAddress = getContract(BASE_MAINNET, "StakedGmxTracker");
  const bonusGmxTrackerAddress = getContract(BASE_MAINNET, "BonusGmxTracker");
  const feeGmxTrackerAddress = getContract(BASE_MAINNET, "FeeGmxTracker");
  const stakedGlpTrackerAddress = getContract(BASE_MAINNET, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(BASE_MAINNET, "FeeGlpTracker");
  const rewardTrackersForStakingInfo = [stakedGmxTrackerAddress, bonusGmxTrackerAddress, feeGmxTrackerAddress, stakedGlpTrackerAddress, feeGlpTrackerAddress,];
  const fetcherDependencies = useMemo(() => [
    `StakeV2:stakingInfo:${active}`,
    BASE_MAINNET,
    rewardReaderAddress,
    "getStakingInfo",
    account || PLACEHOLDER_ACCOUNT,
  ], [active, rewardReaderAddress, account]);

  const { data: stakingInfo } = useSWR(fetcherDependencies,
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 60000,
    }
  );

  useEffect(() => {
    const stakingData = getStakingData(stakingInfo);
    setStakingData(stakingData);
  }, [stakingInfo]);



  let poolIDs = [0]
  if (numberOfPools) {
    poolIDs = Array.from({ length: numberOfPools }, (_, index) => index);
  }

  const getTokenSymbol = (tokenIndex) => {
    if (tokenSymbols) {
      return tokenSymbols[tokenIndex]
    } else {
      return ("Loading")
    }
  }

  const getTokensToUsd = (tokenIndex, numOfTokens, tokenPrices) => {
    if (tokenPrices[tokenIndex]) {
      const price = BigNumber.from(tokenPrices[tokenIndex].toString());
      const tokens = BigNumber.from(numOfTokens.toString())
      const divisor = BigNumber.from("1000000000000000000")
      return BigNumber.from((price.mul(tokens).div(divisor)))
    } else {
      return BigNumber.from(0)
    }
  }

  const fetchMultipliers = async (poolID) => {
    const provider = getProvider(library, chainId);
    const contract = new ethers.Contract(MeridianMultiPoolStakingAddress, MSTStaking.abi, provider);
    try {
      const result = await contract.getPresetMultipliers(poolID);
      return parseInt(result.toString(), 10);
    } catch (error) {
      console.error(`Error fetching multipliers for poolID ${poolID}:`, error.message);
      throw error;
    }
  };
  const getMutiplierKey = (poolID) => `fetchMultipliers-${poolID}`;
  const { data: presetMultipliers, pmerror } = useSWR(poolIDs.map(getMutiplierKey), (...keys) => Promise.all(keys.map((key) => fetchMultipliers(parseInt(key.split('-')[1], 10)))));

  const fetchDurations = async (poolID) => {
    const provider = getProvider(library, chainId);
    const contract = new ethers.Contract(MeridianMultiPoolStakingAddress, MSTStaking.abi, provider);
    try {
      const result = await contract.getPresetDurations(poolID);
      return parseInt(result.toString(), 10);
    } catch (error) {
      console.error(`Error fetching durations for poolID ${poolID}:`, error.message);
      throw error;
    }
  };
  const getDurationKey = (poolID) => `fetchDurations-${poolID}`;
  const { data: presetDurations, pderror } = useSWR(poolIDs.map(getDurationKey), (...keys) => Promise.all(keys.map((key) => fetchDurations(parseInt(key.split('-')[1], 10)))));


  // Calculate the approx APR for Mint USDM staking on Telos
  const { data: currentTlosStakingSnapshot } = useSWR(
    [`StakeV2:nativeSnapshotOnUSDMStaking:${TELOS_MAINNET}`, TELOS_MAINNET, getContract(TELOS_MAINNET, "MeridianUSDMStaking"), "F_ETH",],
    { fetcher: contractFetcher(undefined, USDMStaking), }
  );
  const { data: currentUsdmStakingOnTlos } = useSWR(
    [`StakeV2:usdmSnapshotOnUSDMStaking:${TELOS_MAINNET}`, TELOS_MAINNET, getContract(TELOS_MAINNET, "MeridianUSDMStaking"), "F_LUSD",],
    { fetcher: contractFetcher(undefined, USDMStaking), }
  );
  const { changeInNativeSnapshot: telosDelta,
    changeInUsdmSnapshot: usdmDeltaTelos,
    changeInTimeStamp: timeDeltaTelos } = getDeltas(TELOS_MAINNET, currentTlosStakingSnapshot, currentUsdmStakingOnTlos);

  useEffect(() => {
    if (!mstPrice || mstPrice == 0 || !telosDelta || !usdmDeltaTelos || !timeDeltaTelos) {
      console.warn('data not available yet. Skipping calculations.');
      return;
    }
    if (networkTokenPrices.hasOwnProperty('TLOS')) {
      const nativeTokenPrice = BigNumber.from(networkTokenPrices['TLOS'].toString());
      const nativeRewards = BigNumber.from(telosDelta.mul(nativeTokenPrice).div(expandDecimals(1, NATIVE_DECIMALS)))
      const rewardsUSD = BigNumber.from(((nativeRewards.add(usdmDeltaTelos)).mul(expandDecimals(1, USD_DECIMALS))).toString())
      const annualRewardsPerStakeUSD = BigNumber.from(rewardsUSD.mul(365).mul(SECONDS_PER_DAY).div(timeDeltaTelos));
      const APR = BigNumber.from((annualRewardsPerStakeUSD.div(mstPrice).mul(100)).toString());
      setUsdmStakingAprTlos(APR)
    }
  }, [mstPrice, networkTokenPrices,]);

  // Calculate the approx APR for Mint USDM staking on Base
  const { data: currentBaseStakingSnapshot } = useSWR(
    [`StakeV2:nativeSnapshotOnUSDMStaking:${BASE_MAINNET}`, BASE_MAINNET, getContract(BASE_MAINNET, "MeridianUSDMStaking"), "F_ETH",],
    { fetcher: contractFetcher(undefined, USDMStaking), }
  );
  const { data: currentUsdmStakingOnBase } = useSWR(
    [`StakeV2:usdmSnapshotOnUSDMStaking:${BASE_MAINNET}`, BASE_MAINNET, getContract(BASE_MAINNET, "MeridianUSDMStaking"), "F_LUSD",],
    { fetcher: contractFetcher(undefined, USDMStaking), }
  );
  const { changeInNativeSnapshot: ethDelta,
    changeInUsdmSnapshot: usdmDeltaBase,
    changeInTimeStamp: timeDeltaBase } = getDeltas(BASE_MAINNET, currentBaseStakingSnapshot, currentUsdmStakingOnBase);

  useEffect(() => {
    if (!mstPrice || mstPrice == 0 || !ethDelta || !usdmDeltaBase || !timeDeltaBase) {
      console.warn('data not available yet. Skipping calculations.');
      return;
    }
    if (networkTokenPrices.hasOwnProperty('ETH')) {
      const nativeTokenPrice = BigNumber.from(networkTokenPrices['ETH'].toString());
      const nativeRewards = BigNumber.from(ethDelta.mul(nativeTokenPrice).div(expandDecimals(1, NATIVE_DECIMALS)))
      const rewardsUSD = BigNumber.from(((nativeRewards.add(usdmDeltaBase)).mul(expandDecimals(1, USD_DECIMALS))).toString())
      const annualRewardsPerStakeUSD = BigNumber.from(rewardsUSD.mul(365).mul(SECONDS_PER_DAY).div(timeDeltaBase));
      const APR = BigNumber.from((annualRewardsPerStakeUSD.div(mstPrice).mul(100)).toString());
      setUsdmStakingAprBase(APR)
    }
  }, [mstPrice, networkTokenPrices,]);


  const AprTable = ({ presetMultipliers, presetDurations, estimatedBaseApr }) => {
    if (!presetMultipliers || !presetDurations) {
      return <div>Loading ...</div>;
    }
    const daysArray = presetDurations.map((value) => `${value} ${value === 1 ? 'Day' : 'Days'}`);

    return (
      <div>
        <div className="App-card-row" >
          <div className="App-card-table-headers">Min Lockup</div>
          <div className="App-card-table-headers">APR %</div>
        </div>
        {presetMultipliers.map((value, index) => (
          <div className="App-card-row" key={index}>
            <div className="label">
              {daysArray[index]}
            </div>
            <div>
              {formatAmount(getRateFromMultiplier(value, estimatedBaseApr), 19, 1, false)}%
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Get the array of investment positions for this user and then useSWR to store them in userInvestments
  const fetchUserInvestments = async (poolID, account) => {
    const provider = getProvider(library, chainId);
    const contract = new ethers.Contract(MeridianMultiPoolStakingAddress, MSTStaking.abi, provider);
    try {
      const result = await contract.getUserInvestments(account, poolID);
      return result;
    } catch (error) {
      console.error(`Error fetching user investments for poolID ${poolID}:`, error.message);
      throw error;
    }
  };
  const getUserInvestmentsKey = (poolID) => `fetchUserInvestmentsKey-${poolID}`;
  const { data: userInvestments, UIerror } = useSWR(poolIDs.map(getUserInvestmentsKey), (...keys) => Promise.all(keys.map((key) => fetchUserInvestments(parseInt(key.split('-')[1], 10), account || PLACEHOLDER_ACCOUNT))));
  // userInvestments

  // Sum up all MST investment positions to get the total MST invested by this user - store in totalUserInvestment
  useEffect(() => {
    let newTotalInvestment
    if (userInvestments) {
      newTotalInvestment = userInvestments.flat().reduce((sum, tuple) => sum.add(tuple[0]), BigNumber.from(0));
    } else {
      newTotalInvestment = BigNumber.from(0)
    }
    setTotalUserInvestment(newTotalInvestment);
  }, [userInvestments]);
  // totalUserInvestment

  // Sum up all MST investment positions for this user where timelock has completed and store value in availableToRedeem
  useEffect(() => {
    setAvailableToRedeem(GetMaxAvailableToRedeem(userInvestments));
  }, [userInvestments]);
  // availableToRedeem

  // Calculate the average APR that this user is earning by creating a weighted average 
  // based on the MST investment and APR of each indevidual position. Store result in averageWeightedApr
  const getAverageWeightedApr = (userInvestments, estimatedBaseApr) => {
    if (!userInvestments || !estimatedBaseApr) {
      return BigNumber.from(0);
    }
    let totalWeightedValue = BigNumber.from(0);
    let totalTuple0 = BigNumber.from(0);
    userInvestments.forEach((poolData) => {
      poolData.forEach((tuple) => {
        const tuple0 = BigNumber.from(tuple[0]);
        const rateMultiplier = getRateFromMultiplier(tuple[1], estimatedBaseApr);
        const weightedValue = tuple0.mul(rateMultiplier);

        totalWeightedValue = totalWeightedValue.add(weightedValue);
        totalTuple0 = totalTuple0.add(tuple0);
      });
    });
    if (totalTuple0.isZero()) {
      return BigNumber.from(0);
    }
    const weightedAverage = totalWeightedValue.div(totalTuple0);
    return weightedAverage;
  };
  useEffect(() => {
    setAverageWeightedApr(getAverageWeightedApr(userInvestments, estimatedBaseApr));
  }, [userInvestments, estimatedBaseApr]);
  // averageWeightedApr


  const PositionSummary = ({ userInvestments }) => {
    if (!userInvestments || userInvestments.length === 0) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <div className="App-card-row-with-5-columns">
          <div>{formatAmount(totalUserInvestment, MST_DECIMALS, 2, true)}</div>
          <div>{formatAmount(averageWeightedApr, 19, 1, false)}</div>
          <div>{formatAmount(availableToRedeem, MST_DECIMALS, 2, true)}</div>
          <div>{formatAmount((totalUserInvestment.sub(availableToRedeem)), MST_DECIMALS, 2, true)}</div>
          <div>{formatAmount(totalTokensToUsd, MST_DECIMALS, 2, true)}</div>
        </div>
        <div className="App-card-row">
          <div className="App-card-bottom-placeholder">
            <div className="App-card-divider"></div>
            <div className="App-card-options">
              <div className="Button-container">
                {active && (
                  <button className="App-button-option App-card-option Button-min-witdth" onClick={() => showStakeMstModal()}>
                    <Trans>Stake</Trans>
                  </button>
                )}
                {active && (
                  <button className="App-button-option App-card-option Button-min-witdth" onClick={() => showUnstakeMstModal()}>
                    <Trans>Unstake</Trans>
                  </button>
                )}
                {!active && (
                  <button className="App-button-option App-card-option Button-min-witdth" onClick={() => connectWallet()}>
                    <Trans>Connect Wallet</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="App-card-bottom">
            <div className="App-card-divider"></div>
            <div className="App-card-options">
              <div className="Button-container">
                {active && (
                  <button className="App-button-option App-card-option Button-min-witdth" onClick={() => showStakeMstModal()}>
                    <Trans>Stake</Trans>
                  </button>
                )}
                {active && (
                  <button className="App-button-option App-card-option Button-min-witdth" onClick={() => showUnstakeMstModal()}>
                    <Trans>Unstake</Trans>
                  </button>
                )}
                {!active && (
                  <button className="App-button-option App-card-option Button-min-witdth" onClick={() => connectWallet()}>
                    <Trans>Connect Wallet</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PositionsTable = ({ userInvestments }) => {
    if (!userInvestments || userInvestments.length === 0) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        {userInvestments.map((poolData, poolIndex) => (
          <div key={poolIndex}>
            {poolData.map((tuple, tupleIndex) => (
              <div className="App-card-row-with-3-columns" key={tupleIndex}>
                <div>{formatAmount(tuple[0], 18, 2, true)}</div>
                <div>{formatAmount(getRateFromMultiplier(tuple[1], estimatedBaseApr), 19, 1, false)}%</div>
                <div>{daysFromNowString(tuple[2])}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const UserPendingRewardsTable = ({ userPendingRewards }) => {
    if (!userPendingRewards) {
      return <div>Loading ...</div>;
    }
    return (
      <div>
        {userPendingRewards.map((value, index) => (
          <div className="App-card-row-with-3-columns" key={index}>
            <div className="label">
              {getTokenSymbol(index)}
            </div>
            <div>
              {formatAmount(value, 18, 4, true)}
            </div>
            <div>
              {formatAmount(tokensInUsd[index], 18, 2, true)}
            </div>
          </div>
        ))}
        <div className="App-card-row">
          <div className="App-card-bottom-placeholder">
            <div className="App-card-divider"></div>
            <div className="App-card-options">
              <div className="Button-container">
                {active && (
                  <button className="App-button-option App-card-option Button-min-witdth">
                    <Trans>Claim</Trans>
                  </button>
                )}
                {!active && (
                  <button className="App-button-option App-card-option Button-min-witdth" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="App-card-bottom">
            <div className="App-card-divider"></div>
            <div className="App-card-options">
              <div className="Button-container">
                {active && (
                  <button className="App-button-option App-card-option Button-min-witdth" onClick={() => setIsClaimModalVisible(true)}>
                    <Trans>Claim</Trans>
                  </button>
                )}
                {!active && (
                  <button className="App-button-option App-card-option Button-min-witdth" onClick={() => connectWallet()}>
                    <Trans>Connect Wallet</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const OtherNetworkAprRows = ({ targetChainData }) => {

    return (
      <>
        {targetChainData ? (
          targetChainData.filter(({ currentBaseStakingApr }) => currentBaseStakingApr > 0).map(({ targetChainId, currentBaseStakingApr }) => (
            <div className="App-card-row-with-3-columns App-card-row-with-3-columns-wide" key={targetChainId}>
              <div className="label">
                <Tooltip
                  handle={`${getChainName(targetChainId).split(' ')[0]} APR`}
                  position="left-bottom"
                  renderContent={() => (
                    <Trans>
                      Currrent estimated APR for immediate withdrawals
                    </Trans>
                  )}
                />
              </div>
              <div>
                {formatAmount(currentBaseStakingApr, 16, 1, false)}%
              </div>
              <button
                className="App-button-option Button-inline-table"
                onClick={() => handleNetworkCheck(targetChainId, 'https://stake.meridianfinance.net/#/stakemst')}
              >
                <Trans>Switch</Trans>
              </button>
            </div>
          ))
        ) : (
          <div>Loading ...</div>
        )}
      </>
    );
  }



  let { total: totalMstSupply } = useTotalMstSupply();
  let { baseMstOnUsdm: baseMstOnUsdm,
    baseSuperStaking: baseSuperStaking,
    base: baseMstStaked,
    fuse: fuseMstStaked,
    meter: meterMstStaked,
    taiko: taikoMstStaked,
    telos: telosMultiStakingTotal,
    telosMstOnUsdm: telosMstOnUSDMTotal,
    total:
    totalMstStaked } = useTotalMstStaked();

  const mstSupplyUrl = getServerUrl(TELOS_MAINNET, "/mst_supply");
  const { data: mstSupply } = useSWR([mstSupplyUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.text()).then((text) => text.replace(/\n/g, '')),
  });

  const isGmxTransferEnabled = true;

  const balanceData = walletMSTBalance;

  const processedData = getProcessedData(
    balanceData,
    userTotalMstStaked,
    mstPrice,
    mstSupply,
    stakingData,
    EthPrice,
  );

  let totalSupplyUsd;
  if (totalMstSupply && !totalMstSupply.isZero() && mstPrice) {
    totalSupplyUsd = totalMstSupply.mul(mstPrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableGmx = userInvestments ? GetMaxAvailableToRedeem(userInvestments) : bigNumberify(0)

  const showStakeMstModal = () => {
    if (!isGmxTransferEnabled) {
      helperToast.error(t`MST transfers not yet enabled`);
      return;
    }
    setIsStakeModalVisible(true);
    setStakeModalTitle(t`Stake MST`);
    setStakeModalMaxAmount(processedData.mstBalance);
    setStakeValue("");
    setStakingTokenSymbol("MST");
    setStakingTokenAddress(mstAddress);
    setStakingFarmAddress(MeridianMultiPoolStakingAddress);
    setStakeMethodName("stakeMst");
  };


  const showUnstakeMstModal = () => {
    if (!isGmxTransferEnabled) {
      helperToast.error(t`MST transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(t`Unstake MST`);
    let maxAmount = processedData.userTotalMstStaked;
    if (
      processedData.userTotalMstStaked &&
      maxUnstakeableGmx &&
      maxUnstakeableGmx.lt(processedData.userTotalMstStaked)
    ) {
      maxAmount = maxUnstakeableGmx;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("MST");
    setUnstakeMethodName("unStakeMst");
  };


  let totalRewards // JJ needs to be defined
  let earnMsg;
  if (totalRewards && totalRewards.gt(0)) {
    let gmxAmountStr;
    if (processedData.userTotalMstStaked && processedData.userTotalMstStaked.gt(0)) {
      gmxAmountStr = formatAmount(processedData.userTotalMstStaked, 18, 2, true) + "MST";
    }
    let mpAmountStr;

    let glpStr;
    if (processedData.glpBalance && processedData.glpBalance.gt(0)) {
      glpStr = formatAmount(processedData.glpBalance, 18, 2, true) + " MLP";
    }
    const amountStr = [gmxAmountStr, mpAmountStr, glpStr].filter((s) => s).join(", ");
    earnMsg = (
      <div>
        <Trans>
          You are earning {nativeTokenSymbol} rewards with {formatAmount(totalRewards, 18, 2, true)} tokens.
          <br />
          Tokens: {amountStr}.
        </Trans>
      </div>
    );
  }


  return (
    <div className="default-container page-layout">
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        stakingContractAddress={stakingContractAddress}
        stakeMethodName={stakeMethodName}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
        presetDurations={presetDurations}
        presetMultipliers={presetMultipliers}
        estimatedBaseApr={estimatedBaseApr}
        rewardsAvailable={rewardsAvailable}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        stakingContractAddress={stakingContractAddress}
        unstakeMethodName={unstakeMethodName}
        rewardsAvailable={rewardsAvailable}
      />
      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        stakingContractAddress={stakingContractAddress}
        library={library}
        chainId={chainId}
      />
      <SwitchNetworkModal
        isVisible={isSwitchNetworkVisible}
        setIsVisible={setIsSwitchNetworkVisible}
        currentChainId={chainId}
        targetChainId={targetChainId}
        targetPath={targetPath}
      />

      <TitleSection title="Stake MST"
        subTitle="Stake MST and earn your share of protocol fees"
        shortSubTitle="Stake MST and earn protocol fees" />

      <div className="StakeV2-content">
        <div >
          <div className="ThreeCols">
            <div className="App-card primary">
              <div className="App-card-title App-card-title-space-between">
                <Trans>MST Statistics</Trans>
              </div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">MST Price</div>
                  <div>
                    {formatAmount(mstPrice, USD_DECIMALS, 4, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">MST Circulating Supply</div>
                  <div>
                    {formatAmount(totalMstSupply, 18, 0, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Tooltip
                      handle={t`Total MST Staked`}
                      position="left-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            {baseSuperStaking > 0 && (
                              <StatsTooltipRow
                                showDollar={false}
                                label={t`Base SuperStaking`}
                                value={`${formatAmount(baseSuperStaking, 18, 0, true)}`}
                              />)}
                            {baseMstOnUsdm > 0 && (
                              <StatsTooltipRow
                                showDollar={false}
                                label={t`Base USDM Staking`}
                                value={`${formatAmount(baseMstOnUsdm, 18, 0, true)}`}
                              />)}
                            {/* {baseMstStaked > 0 && (
                               <StatsTooltipRow
                              showDollar={false}
                              label={t`Base Staking`}
                              value={`${formatAmount(baseMstStaked, 18, 0, true)}`}
                            />)}
                            */}
                            {fuseMstStaked > 0 && (
                              <StatsTooltipRow
                                showDollar={false}
                                label={t`Fuse Staking`}
                                value={`${formatAmount(fuseMstStaked, 18, 0, true)}`}
                              />)}
                            {meterMstStaked > 0 && (
                              <StatsTooltipRow
                                showDollar={false}
                                label={t`Meter Staking`}
                                value={`${formatAmount(meterMstStaked, 18, 0, true)}`}
                              />)}
                            {taikoMstStaked > 0 && (
                              <StatsTooltipRow
                                showDollar={false}
                                label={t`Taiko Staking`}
                                value={`${formatAmount(taikoMstStaked, 18, 0, true)}`}
                              />)}
                            {telosMultiStakingTotal > 0 && (
                              <StatsTooltipRow
                                showDollar={false}
                                label={t`Telos Staking`}
                                value={`${formatAmount(telosMultiStakingTotal, 18, 0, true)}`}
                              />)}
                            {telosMstOnUSDMTotal > 0 && (
                              <StatsTooltipRow
                                showDollar={false}
                                label={t`Telos USDM Staking`}
                                value={`${formatAmount(telosMstOnUSDMTotal, 18, 0, true)}`}
                              />)}

                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="align-right">
                    {formatAmount(totalMstStaked, 18, 0, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>MST in Wallet</Trans>
                  </div>
                  <div>
                    {formatKeyAmount(processedData, "mstBalance", 18, 0, true)} MST ($
                    {formatKeyAmount(processedData, "mstBalanceUsd", USD_DECIMALS, 2, true)})
                  </div>
                </div>

                <div className="App-card-row">
                  <div className="App-card-bottom-placeholder">
                    <div className="App-card-divider"></div>
                    <div className="App-card-options">
                      <div className="Button-container">
                        {active && (
                          <button className="App-button-option App-card-option Button-min-witdth">
                            <Trans>Claim</Trans>
                          </button>
                        )}
                        {!active && (
                          <button className="App-button-option App-card-option Button-min-witdth" onClick={() => connectWallet()}>
                            Connect Wallet
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="App-card-bottom">
                    <div className="App-card-divider"></div>
                    <div className="App-card-options">
                      <div className="Button-container">
                        <a className="App-button-option App-card-option Button-min-witdth"
                          href={'https://stake.meridianfinance.net/#/tokens'} target="_self">
                          <Trans>Buy MST</Trans>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="App-card">
              <div className="App-card-title with-table-headers">
                <div className="label">
                  <Tooltip
                    handle={`MST Staking on ${getChainName(chainId).split(' ')[0]}`}
                    position="left-bottom"
                    renderContent={() => {
                      return (
                        <Trans>
                          Projected annual earnings for the immediate access pool based on the tokens recieved this week and todays token prices.&nbsp;
                        </Trans>
                      );
                    }}
                  />
                </div>
              </div>

              <div className="App-card-content">
                <AprTable presetMultipliers={presetMultipliers} presetDurations={presetDurations} estimatedBaseApr={estimatedBaseApr} />
                <div className="App-card-row">
                  <div className="App-card-bottom-placeholder">
                    <div className="App-card-divider"></div>
                    <div className="App-card-options">
                      <div className="Button-container">
                        {active && (
                          <button className="App-button-option App-card-option Button-min-witdth">
                            <Trans>Claim</Trans>
                          </button>
                        )}
                        {!active && (
                          <button className="App-button-option App-card-option Button-min-witdth" onClick={() => connectWallet()}>
                            Connect Wallet
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="App-card-bottom">
                    <div className="App-card-divider"></div>
                    <div className="App-card-options">
                      <div className="Button-container">
                        <button className="App-button-option App-card-option Button-min-witdth" onClick={() => showStakeMstModal()}>
                          <Trans>Stake</Trans>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="App-card">
              <div className="App-card-title with-table-headers">
                <Trans>Other MST Staking Rates</Trans>
              </div>
              <div className="App-card-content">
                <div className="App-card-row-with-3-columns App-card-row-with-3-columns-wide" >
                  <div className="App-card-table-headers">APR %</div>
                </div>
                {otherChainAprData && otherChainAprData.length > 0 && (
                  <OtherNetworkAprRows targetChainData={otherChainAprData} handleNetworkCheck={handleNetworkCheck} />
                )}
                <div className="App-card-row-with-3-columns App-card-row-with-3-columns-wide">
                  <div className="label">
                    <Tooltip
                      handle={t`Telos Mint APR`}
                      position="left-bottom"
                      renderContent={() => {
                        return (
                          <Trans>
                            Projected annual earnings for MST staking at  mint.meridianfinance on the Telos network. Based on todays token prices and fees recieved since 24 Jan 24.&nbsp;
                          </Trans>
                        );
                      }}
                    />
                  </div>
                  <div>
                    {formatAmount(usdmStakingAprTlos, 18, 1, false)}%
                  </div>
                  {chainId === TELOS_MAINNET && (
                    <button className="App-button-option Button-inline-table"
                      onClick={() => handleRedirect('https://mint.meridianfinance.net/#/staking')}>
                      <Trans>Stake</Trans>
                    </button>
                  )}
                  {chainId !== TELOS_MAINNET && (
                    <button className="App-button-option Button-inline-table"
                      onClick={() => handleNetworkCheck(TELOS_MAINNET, 'https://mint.meridianfinance.net/#/staking')}>
                      <Trans>Switch</Trans>
                    </button>
                  )}
                </div>
                <div className="App-card-row-with-3-columns App-card-row-with-3-columns-wide">
                  <div className="label">
                    <Tooltip
                      handle={t`Base Mint APR`}
                      position="left-bottom"
                      renderContent={() => {
                        return (
                          <Trans>
                            Projected annual earnings for MST staking at  mint.meridianfinance on the Base network.  Based on todays token prices and fees recieved since 25 Jan 24.&nbsp;
                          </Trans>
                        );
                      }}
                    />
                  </div>
                  <div>
                    {formatAmount(usdmStakingAprBase, 18, 1, false)}%
                  </div>
                  {chainId === BASE_MAINNET && (
                    <button className="App-button-option Button-inline-table"
                      onClick={() => handleRedirect('https://mint.meridianfinance.net/#/staking')}>
                      <Trans>Stake</Trans>
                    </button>
                  )}
                  {chainId !== BASE_MAINNET && (
                    <button className="App-button-option Button-inline-table"
                      onClick={() => handleNetworkCheck(BASE_MAINNET, 'https://mint.meridianfinance.net/#/')}>
                      <Trans>Switch</Trans>
                    </button>
                  )}
                </div>
                <div className="App-card-row-with-3-columns App-card-row-with-3-columns-wide">
                  <div className="label">
                    <Tooltip
                      handle={t`APR SuperStaking (Base)`}
                      position="left-bottom"
                      renderContent={() => {
                        return (
                          <Trans>
                            Projected annual earnings for MST SuperStaking on the Base network. Excludes user boosted rewards.&nbsp;
                          </Trans>
                        );
                      }}
                    />
                  </div>
                  <div>
                    {formatAmount(processedData.mstSuperStakingApr, DIA_DECIMALS, 1, false)}%
                  </div>
                  {chainId === BASE_MAINNET && (
                    <button className="App-button-option Button-inline-table"
                      onClick={() => handleRedirect('https://classic.meridianfinance.net/#/earn')}>
                      <Trans>Stake</Trans>
                    </button>
                  )}
                  {chainId !== BASE_MAINNET && (
                    <button className="App-button-option Button-inline-table"
                      onClick={() => handleNetworkCheck(BASE_MAINNET, 'https://classic.meridianfinance.net/#/earn')}>
                      <Trans>Switch</Trans>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="FullRow">
            <div className="App-card">
              <div className="App-card-title with-table-headers">
                <Trans>Positions Summary</Trans>
              </div>
              <div className="App-card-content">
                <div className="App-card-row-with-5-columns">
                  <div className="App-card-table-headers">Total Staked</div>
                  <div className="App-card-table-headers">
                    <Tooltip
                      handle={t`Avg APR (%) `}
                      position="left-bottom"
                      renderContent={() => {
                        return (
                          <Trans>
                            <div style={{ fontWeight: 'normal' }}>
                              Projected reward based on current Estimated Base APR, current user positions and user lockup commitments .&nbsp;
                            </div>
                          </Trans>
                        );
                      }}
                    />
                  </div>
                  <div className="App-card-table-headers">Available Now</div>
                  <div className="App-card-table-headers">Still to Mature</div>
                  <div className="App-card-table-headers">
                    <Tooltip
                      handle={t`Current Earnings ($)`}
                      position="left-bottom"
                      renderContent={() => {
                        return (
                          <Trans>
                            Based on current token prices .&nbsp;
                          </Trans>
                        );
                      }}
                    />
                  </div>
                </div>
                <PositionSummary userInvestments={userInvestments} />
              </div>
            </div>
          </div>

          <div className="TwoCols">
            <div className="App-card">
              <div className="App-card-title with-table-headers">
                <Trans>Positions Table</Trans>
              </div>
              <div className="App-card-content">
                <div className="App-card-row-with-3-columns">
                  <div className="App-card-table-headers">Staked Tokens</div>
                  <div className="App-card-table-headers">Forecast APR</div>
                  <div className="App-card-table-headers">When Available</div>
                </div>
                <PositionsTable userInvestments={userInvestments} />
              </div>
            </div>
            <div className="App-card">
              <div className="App-card-title with-table-headers">
                <Trans>Pending Rewards</Trans>
              </div>
              <div className="App-card-content">
                <div className="App-card-row-with-3-columns" >
                  <div className="App-card-table-headers">Token</div>
                  <div className="App-card-table-headers">Amount</div>
                  <div className="App-card-table-headers">USD</div>
                </div>
                <UserPendingRewardsTable userPendingRewards={userPendingRewards} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
