import {TELOS_TESTNET, TELOS_MAINNET } from "./chains";

export const GMX_STATS_API_URL = "https://statsapi.meridianfinance.net/api";

const BACKEND_URLS = {
  default: "",

  [TELOS_TESTNET]: "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/",
  [TELOS_MAINNET]: "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/",
};
export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}
