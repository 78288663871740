import { getConnection } from "./Helpers"
import { TELOS_MAINNET, FUSE_MAINNET, METER_MAINNET, TAIKO_MAINNET } from "config/chains";
import DIAPriceFeed from "abis/DIAPriceFeed.json";
import FusePriceFeed from "abis/FusePriceFeed.json";
import MeterOracle from "abis/MeterOracle.json"; 

import { expandDecimals } from "lib/numbers";
import {MST_DECIMALS, DIA_DECIMALS, METER_DECIMALS } from "lib/legacy";


const fetchPrice = async (endpoint) => {
    const key = (endpoint.key).toString()
    if (key === 'stableCoin') {
      return 1E18;
    } else if (key === "" || key === "undefined") {
      return 0;
    }
    try {
      switch (endpoint.source) {
        case 'TELOS': {
          const contract = getConnection(TELOS_MAINNET, "DIA_ORACLE",DIAPriceFeed.abi)
          const result = await contract.getValue(key);
          return expandDecimals(result[0], (MST_DECIMALS - DIA_DECIMALS))
        };
        case 'FUSE': {
          const priceFeedContract = getConnection(FUSE_MAINNET, "PRICE_FEED", FusePriceFeed.abi)
          const result = await priceFeedContract.fetchPrice(); // fetches the price of WFUSE
          return result;
        };
        case 'TAIKO': {
          const priceFeedContract = getConnection(TAIKO_MAINNET, "PRICE_FEED", MeterOracle)
          const result = await priceFeedContract.getAssetPrice(key); // fetches the price of WFUSE
          return expandDecimals(result, (MST_DECIMALS - METER_DECIMALS));
        };
        case 'METER': {
            const contract = getConnection(METER_MAINNET, "METER_ORACLE",MeterOracle)
            const result = await contract.getAssetPrice(key);
            return expandDecimals(result, (MST_DECIMALS - METER_DECIMALS));
        };
        default:
          return 0
      }
    } catch (error) {
      console.error(`Error fetching token price for ${key}:`, error.message);
      throw error;
    }
  }

export default fetchPrice;