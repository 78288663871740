import React from "react";
import { library, IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBridge,
  faCircleNotch,
  faCheckCircle,
  faCoins,
  faEllipsisH,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
  faTrash,
  faChartLine,
  faRedo,
  faHandshake,
  faHandHoldingDollar,
  faHistory,
  faChevronLeft,
  faChevronRight,
  faUserCircle,
  faWallet,
  faExternalLinkAlt,
  faClipboardCheck,
  faCog,
  faCheck,
  faPlug,
  faExclamationCircle,
  faAngleUp,
  faAngleDoubleUp,
  faAngleDown,
  faAngleDoubleDown,
  faPen,
  faSeedling,
  faHandPaper,
  faHeartbeat,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import { faClipboard, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

library.add(
  faBridge,
  faCircleNotch,
  faCheck,
  faCheckCircle,
  faCoins,
  faEllipsisH,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
  faTrash,
  faChartLine,
  faRedo,
  faHandshake,
  faHandHoldingDollar,
  faHistory,
  faChevronLeft,
  faChevronRight,
  faClipboard,
  faClipboardCheck,
  faUserCircle,
  faWallet,
  faExternalLinkAlt,
  faCog,
  faPlug,
  faExclamationCircle,
  faAngleUp,
  faAngleDoubleUp,
  faAngleDown,
  faAngleDoubleDown,
  faPen,
  faHandPaper,
  faHeartbeat,
  faSeedling,
  faBars,
  faQuestionCircle
);

const getIcon = function(name) {
    switch (name) {
      case "clipboard":
        return ["far", "clipboard"];
      case "question-circle":
        return ["far", "question-circle"];
      default:
        return name;
    }
  };
  

export const IconProps = {
    style: "style",
    size: "size",
    color: "color",
    spin: "spin",
    name: "name",
  };
  
export const Icon = function({ name, style, ...rest }) {
    return <FontAwesomeIcon style={style} icon={getIcon(name)} {...rest} />;
  };
  