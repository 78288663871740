import React from "react";
import "./TitleSection.scss";
import PageSwitcher from "../PageSwitcher/PageSwitcher";

const TitleSection = ({ title, subTitle, shortSubTitle }) => {
  return (
    <section id="secondary-nav-top">
      <div>
        <h1>{title}</h1>
        <div className="d-flex justify-content-center responsive-height">
          <p className="long-subtitle px-3 px-lg-0">
            {subTitle}
          </p>
          <p className="short-subtitle px-3 px-lg-0">
            {shortSubTitle}
          </p>
        </div>
          <PageSwitcher/>
      </div>
    </section>
  );
};

export default TitleSection;
