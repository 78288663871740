import React from "react";
import "./EcoFooter.scss";

const EcoFooter = () => {
  return (
    <section id="eco-footer">
      <div className="container">
        <h2 className="px-2">Integrate Meridian Assets in your Protocol.</h2>
        <p>
          Enjoy the power of Meridian’s assets in your project, unlocking
          untapped liquidity on the ecosystem. <br />
          Connect with us to get technical and liquidity support!
        </p>
        <div className="text-center">
          <a href="https://app.gitbook.com/o/IUxOGV1Z2Tj57GrrpLTt/s/znH0wmdBqe8s23j9vKpZ/resources/contact" target="_blank">
            <button>Integrate Now</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default EcoFooter;
