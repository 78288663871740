import { TELOS_TESTNET, TELOS_MAINNET } from "./chains";

export const SUBGRAPH_URLS = {

  [TELOS_TESTNET]: {
    stats: "https://subgraph.meridianfinance.net/subgraphs/name/perpetuals-stats",
    referrals: "https://subgraph.meridianfinance.net/subgraphs/name/perpetuals-referrals",
  },

  [TELOS_MAINNET]: {
    stats: "https://subgraph.meridianfinance.net/subgraphs/name/perpetuals-stats",
    referrals: "https://subgraph.meridianfinance.net/subgraphs/name/perpetuals-referrals",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
