export const TOKEN_PRICE_ENDPOINTS: { [address: string]: { Name: string, Symbol: string, PriceEndPoint: object, isStableCoin: boolean } } = {
    "0x568524DA340579887db50Ecf602Cd1BA8451b243": {
        Name: "meridian-mst",
        Symbol: "MST",
        PriceEndPoint: {source: "TLOS", key: "MST/TLOS"},  
        isStableCoin: false,
    },
    "0xA0fB8cd450c8Fd3a11901876cD5f17eB47C6bc50": {
        Name: "ethereum",
        Symbol: "ETH",
        PriceEndPoint: {source: "TLOS", key: "ETH/USD"},
        isStableCoin: false,
    },       
    "0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9": {
        Name: "usdm-telos",
        Symbol: "USDM",
        PriceEndPoint: {source: "", key: "stableCoin"},
        isStableCoin: true,
    },
    "0x4447863cddABbF2c3dAC826f042e03c91927A196": {
        Name: "usdm-fuse",
        Symbol: "USDM",
        PriceEndPoint: {source: "", key: "stableCoin"},
        isStableCoin: true,
    },
    "0x0BE9e53fd7EDaC9F859882AfdDa116645287C629": {
        Name: "wfuse",
        Symbol: "WFUSE",
        PriceEndPoint: {source: "FUSE", key: "88"},
        isStableCoin: false,
    },
    "0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3": {
        Name: "mtrg",
        Symbol: "MTRG",
        PriceEndPoint: {source: "METER", key: "0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3"},      // TO DO
        isStableCoin: false,
    },  
    "0xA9d23408b9bA935c230493c40C73824Df71A0975": {
        Name: "taiko",
        Symbol: "TAIKO",
        PriceEndPoint: {source: "TAIKO", key: "0xA9d23408b9bA935c230493c40C73824Df71A0975"},      // TO DO
        isStableCoin: false,
    },   
    //Add more tokens here
  };

  export const NETWORK_TOKEN_ENDPOINTS: { [address: string]: { Name: string, Symbol: string, PriceEndPoint: object, isStableCoin: boolean } } = {
    "0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E": { // Telos Mainnet
        Name: "wtlos",
        Symbol: "TLOS",
        PriceEndPoint: {source: "TELOS", key: "TLOS/USD"},  
        isStableCoin: false,
    },
    "0xA0fB8cd450c8Fd3a11901876cD5f17eB47C6bc50": {  // Base Mainnet
        Name: "ethereum",
        Symbol: "ETH",
        PriceEndPoint: {source: "TELOS", key: "ETH/USD"},
        isStableCoin: false,
    },       
    "0x0BE9e53fd7EDaC9F859882AfdDa116645287C629": { // Fuse Mainnet
        Name: "wfuse",
        Symbol: "FUSE",
        PriceEndPoint: {source: "FUSE", key: "88"},
        isStableCoin: false,
    },
    "0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3": {  // Meter Mainnet
        Name: "mtrg",
        Symbol: "MTRG",
        PriceEndPoint: {source: "METER", key: "0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3"},      // TO DO
        isStableCoin: false,
    },    
    //Add more tokens here
  };