import React, { useState } from "react";
import "./tradepage.css";
import BaseTrade from "../../components/TokenSwaps/BaseTrade";
import TelosEVM from "../../components/TokenSwaps/TelosEVM";
import FuseTrade from "../../components/TokenSwaps/FuseTrade";
import MeterTrade from "../../components/TokenSwaps/MeterTrade";
import shape from "../../img/shape.png"
import Navbar from "../../components/Navbar/Navbar";
import PageSwitcher from "../../components/PageSwitcher/PageSwitcher";
import { ThemeProvider } from "./ThemeContext";
import { ToggleProvider } from "../../components/TokenSwaps/ToggleContext";
import TitleSection from "../../components/TitleSection/TitleSection";


const TradePage = () => {

    const [islink, setIsLink] = useState("base");

    return (
        <ThemeProvider>
            <ToggleProvider>
                <div className="default-container page-layout">
                <TitleSection title="Trade Tokens" 
                            subTitle="USDM is the stablecoin of Meridian, while MST serves as the secondary token that enables users to earn protocol fees through staking" 
                            shortSubTitle = "Trade tokens with our trusted partners" />
                <div className="trade">
                    <div className="tradelinks" style={{ marginTop: '20px' }}> {/* Add margin-top */}
                        <li
                            onClick={() => setIsLink("base")}
                            className={islink === "base" ? "isActive" : ""}
                        >
                            Base
                        </li>
                        <li
                            onClick={() => setIsLink("teloTrade")}
                            className={islink === "teloTrade" ? "isActive" : ""}
                        >
                            TelosEVM
                        </li>
                        <li
                            onClick={() => setIsLink("fuse")}
                            className={islink === "fuse" ? "isActive" : ""}
                        >
                            Fuse
                        </li>
                        <li
                            onClick={() => setIsLink("meter")}
                            className={islink === "meter" ? "isActive" : ""}
                        >
                            Meter
                        </li>
                    </div>
                    {islink === "base" && <BaseTrade />}
                    {islink === "teloTrade" && <TelosEVM />}
                    {islink === "fuse" && <FuseTrade />}
                    {islink === "meter" && <MeterTrade />}
                </div>
            </div>
            </ToggleProvider>
        </ThemeProvider>
    );
};

export default TradePage;
