import { BigNumber, ethers } from "ethers";
import { gql } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSWR from "swr";


import Token from "abis/Token.json";

import { getContract } from "config/contracts";
import { BASE_MAINNET, TELOS_MAINNET, FUSE_MAINNET, METER_MAINNET, TAIKO_MAINNET, getConstant, getHighExecutionFee } from "config/chains";

import { getServerBaseUrl, getServerUrl } from "config/backend";
import { getGmxGraphClient } from "lib/subgraph/clients";
import { callContract, contractFetcher } from "lib/contracts";
import { bigNumberify, expandDecimals, parseValue } from "lib/numbers";

export * from "./prices";

const { AddressZero } = ethers.constants;

export function useUserStat(chainId) {
  const query = gql(`{
    userStat(id: "total") {
      id
      uniqueCount
    }
  }`);

  const [res, setRes] = useState<any>();

  useEffect(() => {
    // eslint-disable-next-line no-console
    getGmxGraphClient(chainId)?.query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  return res ? res.data.userStat : null;
}

// use only the supply endpoint on arbitrum, it includes the supply on avalanche
export function useTotalMstSupply() {
  const gmxSupplyUrlArbitrum = getServerUrl(TELOS_MAINNET, "/mst_supply");

  const { data: gmxSupply, mutate: updateGmxSupply } = useSWR([gmxSupplyUrlArbitrum], {
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.text()).then((text) => text.replace(/\n/g, '')),
  });

  return {
    total: gmxSupply ? bigNumberify(gmxSupply) : undefined,
    mutate: updateGmxSupply,
  };
}

export function useMstPrice() {
  const mstPriceUrlTelos = getServerUrl(TELOS_MAINNET, "/mst_price_30");
  const { data: mstPrice, mutate: updateMst } = useSWR([mstPriceUrlTelos], {
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.text()).then((text) => text.replace(/\n/g, '')),
  });

  const refreshInterval = 30000;
  // Memoize the result for the initial setup
  const memoizedResult = useMemo(() => {
    return {
      total: mstPrice ? bigNumberify(mstPrice) : bigNumberify(0),
      mutate: updateMst,
    };
  }, [mstPrice]);

  // Use useEffect for periodic updates
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateMst(); // Trigger a refresh every interval
    }, refreshInterval);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [updateMst, refreshInterval]);

  return memoizedResult;
}

//  return {
//    total: mstPrice ? bigNumberify(mstPrice) : bigNumberify(0),
//    mutate: updateMst,
//  };
// }


export function useTotalMstStaked() {
  const MeridianUSDMStakingAddressBase = getContract(BASE_MAINNET, "MeridianUSDMStaking");
  const MeridianSupertakingAddressBase = getContract(BASE_MAINNET, "FeeGmxTracker");
  const MeridianMultiPoolStakingAddressBase = getContract(BASE_MAINNET, "MeridianMultiPoolStaking");
  const MeridianMultiPoolStakingAddressFuse = getContract(FUSE_MAINNET, "MeridianMultiPoolStaking");
  const MeridianMultiPoolStakingAddressMeter = getContract(METER_MAINNET, "MeridianMultiPoolStaking");
  const MeridianMultiPoolStakingAddressTaiko = getContract(TAIKO_MAINNET, "MeridianMultiPoolStaking");
  const MeridianMultiPoolStakingAddressTelos = getContract(TELOS_MAINNET, "MeridianMultiPoolStaking");
  const MeridianUSDMStakingAddressTelos = getContract(TELOS_MAINNET, "MeridianUSDMStaking");
  let totalStakedMst = useRef(bigNumberify(0));

  const { data: stakedMstOnUSDMBase, mutate: updatestakedMstOnUSDMBase } = useSWR<BigNumber>(
    [
      `StakeV2:stakedMstSupply:${BASE_MAINNET}`,
      BASE_MAINNET,
      getContract(BASE_MAINNET, "MST"),
      "balanceOf",
      MeridianUSDMStakingAddressBase,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const { data: stakedSuperStakingBase, mutate: updatestakedSuperStakingBase } = useSWR<BigNumber>(
    [
      `StakeV2:stakedMstSupply:${BASE_MAINNET}`,
      BASE_MAINNET,
      MeridianSupertakingAddressBase,
      "totalSupply",
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const { data: stakedMstBase, mutate: updatestakedMstBase } = useSWR<BigNumber>(
    [
      `StakeV2:stakedMstSupply:${BASE_MAINNET}`,
      BASE_MAINNET,
      getContract(BASE_MAINNET, "MST"),
      "balanceOf",
      MeridianMultiPoolStakingAddressBase,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const { data: stakedMstFuse, mutate: updatestakedMstFuse } = useSWR<BigNumber>(
    [
      `StakeV2:stakedMstSupply:${FUSE_MAINNET}`,
      FUSE_MAINNET,
      getContract(FUSE_MAINNET, "MST"),
      "balanceOf",
      MeridianMultiPoolStakingAddressFuse,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const { data: stakedMstMeter, mutate: updatestakedMstMeter } = useSWR<BigNumber>(
    [
      `StakeV2:stakedMstSupply:${METER_MAINNET}`,
      METER_MAINNET,
      getContract(METER_MAINNET, "MST"),
      "balanceOf",
      MeridianMultiPoolStakingAddressMeter,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const { data: stakedMstTaiko, mutate: updatestakedMstTaiko } = useSWR<BigNumber>(
    [
      `StakeV2:stakedMstSupply:${TAIKO_MAINNET}`,
      TAIKO_MAINNET,
      getContract(TAIKO_MAINNET, "MST"),
      "balanceOf",
      MeridianMultiPoolStakingAddressTaiko,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );


  const { data: stakedMstTelos, mutate: updateStakedMstTelos } = useSWR<BigNumber>(
    [
      `StakeV2:stakedMstSupply:${TELOS_MAINNET}`,
      TELOS_MAINNET,
      getContract(TELOS_MAINNET, "MST"),
      "balanceOf",
      MeridianMultiPoolStakingAddressTelos,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const { data: stakedMstOnUSDMTelos, mutate: updateStakedMstOnUSDMTelos } = useSWR<BigNumber>(
    [
      `StakeV2:stakedMstOnUSDMSupply:${TELOS_MAINNET}`,
      TELOS_MAINNET,
      getContract(TELOS_MAINNET, "MST"),
      "balanceOf",
      MeridianUSDMStakingAddressTelos,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const mutate = useCallback(() => {
    updatestakedMstOnUSDMBase();
    updatestakedSuperStakingBase();
    updatestakedMstBase();
    updatestakedMstFuse();
    updatestakedMstMeter();
    updatestakedMstTaiko();
    updateStakedMstTelos();
    updateStakedMstOnUSDMTelos();
  }, [updatestakedMstOnUSDMBase, updatestakedSuperStakingBase, updatestakedMstBase,
    updatestakedMstFuse, updatestakedMstMeter, updatestakedMstTaiko,
    updateStakedMstTelos, updateStakedMstOnUSDMTelos]);

  let total = BigNumber.from(0)
  if (stakedMstOnUSDMBase) {
    total = total.add(stakedMstOnUSDMBase);
  }
  if (stakedSuperStakingBase) {
    total = total.add(stakedSuperStakingBase);
  }
  if (stakedMstBase) {
    total = total.add(stakedMstBase);
  }
  if (stakedMstFuse) {
    total = total.add(stakedMstFuse);
  }
  if (stakedMstMeter) {
    total = total.add(stakedMstMeter);
  }
  if (stakedMstTaiko) {
    total = total.add(stakedMstTaiko);
  }
  if (stakedMstTelos) {
    total = total.add(stakedMstTelos);
  }
  if (stakedMstOnUSDMTelos) {
    total = total.add(stakedMstOnUSDMTelos);
  }
  totalStakedMst.current = total;

  return {
    baseMstOnUsdm: stakedMstOnUSDMBase,
    baseSuperStaking: stakedSuperStakingBase,
    base: stakedMstBase,
    fuse: stakedMstFuse,
    meter: stakedMstMeter,
    taiko: stakedMstTaiko,
    telos: stakedMstTelos,
    telosMstOnUsdm: stakedMstOnUSDMTelos,
    total: totalStakedMst.current,
    mutate,
  };
}
