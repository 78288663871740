import { BASE_MAINNET, TELOS_TESTNET, TELOS_MAINNET, TAIKO_MAINNET } from "./chains";

const BASELINES = {
    [BASE_MAINNET]: {
        BASELINE_TIMESTAMP: 1706206540,
        BASELINE_NATIVE_SNAPSHOT: 26857448079 ,
        BASELINE_USDM_SNAPSHOT: 1789422171216235 ,
    },
    [TELOS_MAINNET]: {
        BASELINE_TIMESTAMP: 1706097600,
        BASELINE_NATIVE_SNAPSHOT: 8270198497155849,
        BASELINE_USDM_SNAPSHOT: 4813419619000996,
    },
    [TAIKO_MAINNET]: {
      BASELINE_TIMESTAMP: 1724338566,
      BASELINE_NATIVE_SNAPSHOT: 8270198497155849,
      BASELINE_USDM_SNAPSHOT: 4813419619000996,
  }
}

export function getBaseline(chainId: number, name: string): string {
    if (!BASELINES[chainId]) {
      throw new Error(`Unknown chainId ${chainId}`);
    }  
    if (!BASELINES[chainId][name]) {
      throw new Error(`Unknown Constant "${name}" for chainId ${chainId}`);
    }  
    return BASELINES[chainId][name];
  }