import { ethers } from "ethers";
import { getContract } from "./contracts";
import { TELOS_TESTNET, TELOS_MAINNET, FUSE_MAINNET, METER_MAINNET, TAIKO_MAINNET } from "./chains";
import { Token } from "domain/tokens";

export const TOKENS: { [chainId: number]: Token[] } = {
  
  [TELOS_TESTNET]: [
    {
      name: "Telos",
      symbol: "TLOS",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },


    {
      name: "Wrapped TLOS",
      symbol: "WTLOS",
      decimals: 18,
      address: "0xaE85Bf723A9e74d6c663dd226996AC1b8d075AA9",
      isWrapped: true,
      baseSymbol: "TLOS",
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      address: "0xA9F175d3eEB1483d8Ad31D38b5673C951720C49a",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "Ethereum (WETH)",
      symbol: "ETH",
      address: "0x2433D3c00128d1B5C2D241bFAA61aFD6b22810D6",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0x7456Dd35C2FD863C7Aa917E6A46dBF9192ca6B70",
      decimals: 18,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0x929eE3B7cbD7331bb9ac8659CDe7157bCBB0A6a2",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
  ],

  [TELOS_MAINNET]: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped ETH",
      symbol: "WETH",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "USD Base Coin",
      symbol: "USDC",
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USDM",
      symbol: "USDM",
      address: "0x5e06eA564efcB3158a85dBF0B9E017cb003ff56f",
      decimals: 18,
      isStable: true,
      imageUrl: "https://github.com/MeridianDollar/brand-assets/blob/main/USDM.png",
    },
  ],
  [FUSE_MAINNET]: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped ETH",
      symbol: "WETH",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "USD Base Coin",
      symbol: "USDC",
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USDM",
      symbol: "USDM",
      address: "0x5e06eA564efcB3158a85dBF0B9E017cb003ff56f",
      decimals: 18,
      isStable: true,
      imageUrl: "https://github.com/MeridianDollar/brand-assets/blob/main/USDM.png",
    },
  ],
  [METER_MAINNET]: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped ETH",
      symbol: "WETH",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "USD Base Coin",
      symbol: "USDC",
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USDM",
      symbol: "USDM",
      address: "0x5e06eA564efcB3158a85dBF0B9E017cb003ff56f",
      decimals: 18,
      isStable: true,
      imageUrl: "https://github.com/MeridianDollar/brand-assets/blob/main/USDM.png",
    },
  ],
  [TAIKO_MAINNET]: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped ETH",
      symbol: "WETH",
      decimals: 18,
      address: "0xA51894664A773981C6C112C43ce576f315d5b1B6",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0x07d83526730c7438048D55A4fc0b850e2aaB6f0b",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USDC.e",
      symbol: "USDC.e",
      address: "0x19e26B0638bf63aa9fa4d14c6baF8D52eBE86C5C",
      decimals: 6,
      isStable: true,
      imageUrl: "https://github.com/MeridianDollar/brand-assets/blob/main/USDM.png",
    },
  ],
};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  
  [TELOS_TESTNET]: [
    {
      name: "MST",
      symbol: "MST",
      address: getContract(TELOS_TESTNET, "MST"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
  ],

  [TELOS_MAINNET]: [
    {
      name: "MST",
      symbol: "MST",
      address: getContract(TELOS_MAINNET, "MST"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
  ],

  [FUSE_MAINNET]: [
    {
      name: "MST",
      symbol: "MST",
      address: getContract(FUSE_MAINNET, "MST"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
  ],

  [METER_MAINNET]: [
    {
      name: "MST",
      symbol: "MST",
      address: getContract(METER_MAINNET, "MST"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
  ],
  [TAIKO_MAINNET]: [
    {
      name: "MST",
      symbol: "MST",
      address: getContract(TAIKO_MAINNET, "MST"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  
  [TELOS_TESTNET]: {
    // arbitrum
    MST: {
      name: "MST",
      symbol: "MST",
      decimals: 18,
      address: getContract(TELOS_TESTNET, "MST"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
  },
  [TELOS_MAINNET]: {
    // arbitrum
    MST: {
      name: "MST",
      symbol: "MST",
      decimals: 18,
      address: getContract(TELOS_MAINNET, "MST"),
      imageUrl: "https://github.com/MeridianDollar/brand-assets/blob/main/MeridianLogo%20(1).png",
    },
  },
  [FUSE_MAINNET]: {
    // arbitrum
    MST: {
      name: "MST",
      symbol: "MST",
      decimals: 18,
      address: getContract(FUSE_MAINNET, "MST"),
      imageUrl: "https://github.com/MeridianDollar/brand-assets/blob/main/MeridianLogo%20(1).png",
    },
  },
  [METER_MAINNET]: {
    // arbitrum
    MST: {
      name: "MST",
      symbol: "MST",
      decimals: 18,
      address: getContract(METER_MAINNET, "MST"),
      imageUrl: "https://github.com/MeridianDollar/brand-assets/blob/main/MeridianLogo%20(1).png",
    },
  },
  [TAIKO_MAINNET]: {
    // arbitrum
    MST: {
      name: "MST",
      symbol: "MST",
      decimals: 18,
      address: getContract(TAIKO_MAINNET, "MST"),
      imageUrl: "https://github.com/MeridianDollar/brand-assets/blob/main/MeridianLogo%20(1).png",
    },
  },
};

export const ICONLINKS = {
  
  [TELOS_TESTNET]: {
    TLOS: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
    },
  },
  [TELOS_MAINNET]: {
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
    },
    USDM: {
      coingecko: "https://www.coingecko.com/en/coins/usd",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
    }
  },
  [FUSE_MAINNET]: {
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
    },
    USDM: {
      coingecko: "https://www.coingecko.com/en/coins/usd",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
    }
  },
  [METER_MAINNET]: {
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
    },
    USDM: {
      coingecko: "https://www.coingecko.com/en/coins/usd",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
    }
  },
  [TAIKO_MAINNET]: {
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
    },
    USDM: {
      coingecko: "https://www.coingecko.com/en/coins/usd",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
    }
  },
};

export const GLP_POOL_COLORS = {
  ETH: "#6062a6",
  BTC: "#F7931A",
  WBTC: "#F7931A",
  USDC: "#2775CA",
  USDM: "#133763",
  TLOS: "#6a47b5",
  USDT: "#67B18A",
  MIM: "#9695F8",
  FRAX: "#000",
  DAI: "#FAC044",
  UNI: "#E9167C",
  AVAX: "#E84142",
  LINK: "#3256D6",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS = [TELOS_TESTNET, TELOS_MAINNET, FUSE_MAINNET, METER_MAINNET, TAIKO_MAINNET];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDG");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}
