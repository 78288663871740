import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "config/subgraph";
import {TELOS_TESTNET, TELOS_MAINNET } from "config/chains";

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink);

export const telosMainnetReferralsGraphClient = createClient(SUBGRAPH_URLS[TELOS_MAINNET].referrals);
export const telosMainnetGraphClient = createClient(SUBGRAPH_URLS[TELOS_MAINNET].stats);


export function getGmxGraphClient(chainId: number) {
  if (chainId === TELOS_MAINNET) {
    return telosMainnetGraphClient;
  } else if (chainId === TELOS_TESTNET) {
    return null;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}
