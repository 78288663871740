import React from 'react';
import { Trans, t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import { switchNetwork } from "lib/wallets";
import { getChainName } from "config/chains";

export default function SwitchNetworkModal(props) {
    const {
      isVisible,
      setIsVisible,
      currentChainId,
      targetChainId,
      targetPath,
    } = props;
  
    if (currentChainId === targetChainId) {
      return null;
    }
  
    const onClickPrimary = async () => {
      try {
        const newChainName = await switchNetwork(targetChainId, true)
        if (newChainName === getChainName(targetChainId)) {
          window.location.href = targetPath
          setIsVisible(false);
        }
      } catch (error) {
        console.error('Error switching network:', error);
      }
    }
  
    return (
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Switch Network`}>
          <div className="CompoundModal-menu">
            <div>
              <Trans>Switch to {getChainName(targetChainId)}</Trans>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} >
              Switch Network
            </button>
          </div>
        </Modal>
      </div>
    )
  }