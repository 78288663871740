import { getConnection } from "./Helpers"
import { BigNumber } from "ethers";
import { getDailyBaseApr, getWeeklyBaseApr } from "components/Helpers/getBaseApr";
import MSTStaking from "abis/MSTStaking.json";
import fetchPrice  from "./FetchPrice";
import { TOKEN_PRICE_ENDPOINTS } from "config/tokenPriceEndpoints";
import { TELOS_MAINNET, FUSE_MAINNET, METER_MAINNET, NETWORK_METADATA } from "config/chains";

async function getRewardTokens(chainId) {
    const contract = getConnection(chainId, "MeridianMultiPoolStaking", MSTStaking.abi)    
    try {
        const result = await contract.getRewardTokens(); 
        return result;
    } catch (error) {
        console.error(`Error fetching reward tokens for network ${chainId}:`, error.message);
        throw error;
    }
}
async function fetchDailyRateSnapshots(token, chainId) {
    const contract = getConnection(chainId, "MeridianMultiPoolStaking", MSTStaking.abi)
    try {
        const result = await contract.getDailyRateSnapshots(token);
        return result;
    } catch (error) {
        console.error(`Error fetching snapshots for ${token}:`, error.message);
        throw error;
    }
};
// Calculates the base APR for MST staking on specified network (chainId)
const InterestRate = async ({ chainId, mstPrice }) => {
    
    // snapshot calculation of interest only valid 8 days after launch so return use daily calcs during first 8 days
    let dailyCalcMethod = false
    const currentTimestamp = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
    if((NETWORK_METADATA[chainId].launchTime + 691200) > currentTimestamp){ 
        dailyCalcMethod = true
    }

    try {
        // Step 1: Get reward tokens for specified network from the contract
        let rewardTokens = await getRewardTokens(chainId);

        // Step 2: Get the current rate index pointer from the contract
        const contract = getConnection(chainId, "MeridianMultiPoolStaking", MSTStaking.abi);
        const currentRateIndex = await contract.currentRateIndex();

        // Step 3: Get the price keys for the reward tokens
        const priceEndPoints = Array.isArray(rewardTokens) ? rewardTokens.map((address) => TOKEN_PRICE_ENDPOINTS[address]?.PriceEndPoint) : [];
        const getPriceKey = (endpoint) => `StakeMSTDIAKeys:${endpoint}:${chainId}`;
        const priceKeys = priceEndPoints ? priceEndPoints.map(getPriceKey) : [];

        // Step 4: Set up the calls ready to get the daily rate snapshots for the reward tokens from the contract
        const getSnapshotsKey = (rewardToken) => `dailyRateSnapshots-${rewardToken}-${chainId}`;
        const snapshotKeys = rewardTokens ? rewardTokens.map(getSnapshotsKey) : [];
        const snapshotPromises = snapshotKeys.map((key) => {
            const token = key.split('-')[1];
            return fetchDailyRateSnapshots(token, chainId);
        });
        
        // Step 5: Make the contract calls and await results
        const [prices, snapshotResults] = await Promise.all([
            Promise.all(priceEndPoints.map((endpoint) => fetchPrice(endpoint))),
            Promise.all(snapshotPromises)
        ]);

        // Step 6: Calculate the estimated APR
        let APR = BigNumber.from(0);
        if (snapshotResults.length > 0 && mstPrice && currentRateIndex && prices.length > 0) {
            if(dailyCalcMethod ) {
                APR = getDailyBaseApr(rewardTokens, snapshotResults, currentRateIndex, mstPrice, prices);
            } else {
                APR = getWeeklyBaseApr(rewardTokens, snapshotResults, currentRateIndex, mstPrice, prices);
            }
        }
        return APR;
    } catch (error) {
        console.error("Error fetching data:", error);
        return BigNumber.from(0); // Return default APR 
    }
}

export default InterestRate;