import React, { useState, useEffect } from "react";
import { BigNumber, ethers } from "ethers";
import useSWR from "swr";
import USDMStaking from "abis/USDMStaking.json";
import { getContract } from "config/contracts";
import { BASE_MAINNET, TELOS_TESTNET, TELOS_MAINNET } from "config/chains";
import { contractFetcher } from "lib/contracts";
import { getBaseline } from "config/AprBaselineUsdm"


export function getDeltas(Network, currentNativeSnapshot, currentUsdmSnapshot) {  
  let changeInNativeSnapshot = BigNumber.from(0)    
  if(currentNativeSnapshot) {
      changeInNativeSnapshot = BigNumber.from((currentNativeSnapshot.sub(getBaseline(Network,"BASELINE_NATIVE_SNAPSHOT")).toString()))
  }
  let changeInUsdmSnapshot = BigNumber.from(0)
  if(currentUsdmSnapshot) {
      changeInUsdmSnapshot = BigNumber.from((currentUsdmSnapshot.sub(getBaseline(Network,"BASELINE_USDM_SNAPSHOT")).toString()))
  }
  const currentTimestampInSeconds = Math.floor(new Date().getTime() / 1000);
  const changeInTimeStamp = currentTimestampInSeconds - (getBaseline(Network,"BASELINE_TIMESTAMP"));  
  return {
    changeInNativeSnapshot,
    changeInUsdmSnapshot,
    changeInTimeStamp,
  };
}

function getReturnValues(Network, currentNativeSnapshot, currentUsdmSnapshot) {  
  let changeInNativeSnapshot = BigNumber.from(0)    
  if(currentNativeSnapshot) {
      changeInNativeSnapshot = BigNumber.from((currentNativeSnapshot.sub(getBaseline(Network,"BASELINE_NATIVE_SNAPSHOT")).toString()))
  }
  let changeInUsdmSnapshot = BigNumber.from(0)
  if(currentUsdmSnapshot) {
      changeInUsdmSnapshot = BigNumber.from((currentUsdmSnapshot.sub(getBaseline(Network,"BASELINE_USDM_SNAPSHOT")).toString()))
  }
  const currentTimestampInSeconds = Math.floor(new Date().getTime() / 1000);
  const changeInTimeStamp = currentTimestampInSeconds - (getBaseline(Network,"BASELINE_TIMESTAMP"));  
  return {
    changeInNativeSnapshot,
    changeInUsdmSnapshot,
    changeInTimeStamp,
  };
}

/* export function useGetChangeInUSDMStakingSnapshotsTlos() {
  // Get the current snapshots for Native Token
  const { data: currentNativeSnapshot } = useSWR(
      [ `StakeV2:nativeSnapshotOnUSDMStaking:${TELOS_MAINNET}`,
      TELOS_MAINNET,
        getContract( TELOS_MAINNET, "MeridianUSDMStaking"),
        "F_ETH",
      ],
      { fetcher: contractFetcher(undefined, USDMStaking),}
    );
  // and USDM
  const { data: currentUsdmSnapshot } = useSWR(
      [`StakeV2:usdmSnapshotOnUSDMStaking:${TELOS_MAINNET}`,
      TELOS_MAINNET,
        getContract( TELOS_MAINNET, "MeridianUSDMStaking"),
        "F_LUSD",
      ],
      {fetcher: contractFetcher(undefined, USDMStaking),}
    );
  const {changeInNativeSnapshot, changeInUsdmSnapshot, changeInTimeStamp} = getReturnValues(TELOS_MAINNET, currentNativeSnapshot, currentUsdmSnapshot)
  return {
    nativeDelta: changeInNativeSnapshot,
    usdmDelta: changeInUsdmSnapshot,
    timeDelta: changeInTimeStamp,
  };
} */

export function useGetChangeInUSDMStakingSnapshotsTlos() {
  const [snapshotData, setSnapshotData] = useState({
    nativeDelta: null,
    usdmDelta: null,
    timeDelta: null
  });

  const fetchData = async () => {
    try {
      const additionalArgs1 = [
        `StakeV2:nativeSnapshotOnUSDMStaking:${TELOS_MAINNET}`,
        TELOS_MAINNET,
        getContract(TELOS_MAINNET, "MeridianUSDMStaking"),
        "F_ETH"
      ];
      const currentNativeSnapshot = contractFetcher(undefined, USDMStaking, additionalArgs1)
      const additionalArgs2 = [
        `StakeV2:usdmSnapshotOnUSDMStaking:${TELOS_MAINNET}`,
        TELOS_MAINNET,
        getContract(TELOS_MAINNET, "MeridianUSDMStaking"),
        "F_LUSD"
      ];
      const currentUsdmSnapshot = contractFetcher(undefined, USDMStaking, additionalArgs2)

    //    const [currentNativeSnapshot, currentUsdmSnapshot] = await Promise.all([
    //    contractFetcher(undefined, USDMStaking, additionalArgs1),
    //    contractFetcher(undefined, USDMStaking, additionalArgs2)
    //  ]);
      const { changeInNativeSnapshot, changeInUsdmSnapshot, changeInTimeStamp } = getReturnValues(TELOS_MAINNET, currentNativeSnapshot, currentUsdmSnapshot);

      setSnapshotData({
        nativeDelta: changeInNativeSnapshot,
        usdmDelta: changeInUsdmSnapshot,
        timeDelta: changeInTimeStamp
      });
    } catch (error) {
      console.error("Error fetching APR data:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data initially

    const intervalId = setInterval(fetchData, 30000); // Fetch data every 60 seconds

    return () => clearInterval(intervalId); 
  }, []);

  return snapshotData;
}

export function useGetChangeInUSDMStakingSnapshotsBase() {
  const [snapshotData, setSnapshotData] = useState({
    nativeDelta: null,
    usdmDelta: null,
    timeDelta: null
  });

  const fetchData = async () => {
    try {
      const additionalArgs1 = [
        `StakeV2:nativeSnapshotOnUSDMStaking:${BASE_MAINNET}`,
        BASE_MAINNET,
        getContract(BASE_MAINNET, "MeridianUSDMStaking"),
        "F_ETH"
      ];
      const additionalArgs2 = [
        `StakeV2:usdmSnapshotOnUSDMStaking:${BASE_MAINNET}`,
        BASE_MAINNET,
        getContract(BASE_MAINNET, "MeridianUSDMStaking"),
        "F_LUSD"
      ];
      const [currentNativeSnapshot, currentUsdmSnapshot] = await Promise.all([
        contractFetcher(undefined, USDMStaking, additionalArgs1),
        contractFetcher(undefined, USDMStaking, additionalArgs2)
      ]);      
      const { changeInNativeSnapshot, changeInUsdmSnapshot, changeInTimeStamp } = getReturnValues(BASE_MAINNET, currentNativeSnapshot, currentUsdmSnapshot);

      setSnapshotData({
        nativeDelta: changeInNativeSnapshot,
        usdmDelta: changeInUsdmSnapshot,
        timeDelta: changeInTimeStamp
      });
    } catch (error) {
      console.error("Error fetching APR data:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data initially

    const intervalId = setInterval(fetchData, 30000); // Fetch data every 60 seconds

    return () => clearInterval(intervalId); 
  }, []);

  return snapshotData;
}
