import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { FaWallet } from "react-icons/fa";
import "./WalletDisplay.css";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { useChainId } from "lib/chains";
import { NETWORK_METADATA } from "config/chains";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import Token from "abis/Token.json";
import { BigNumber } from "ethers";
import { formatAmount } from "lib/numbers";

export default function DisplayWalletBalances(props) {

  const [nativeBalance, setNativeBalance] = useState('')
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  // get the user wallet native token balance
  useEffect(() => {
    const fetchNativeBalance = async () => {
      if (library && account) {
        try {
          const balance = await library.getBalance(account);
          setNativeBalance(balance);
        } catch (error) {
          console.error('Error fetching native token balance:', error);
        }
      }
    };
    fetchNativeBalance();
  }, [account, library]);

  //get user wallet balance of MST
  const { data: walletMSTBalance } = useSWR<BigNumber>(
    [`StakeMST:walletMSTBalance:${active}`, chainId, getContract(chainId, "MST"), "balanceOf", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  //get user wallet balance of USDM
  const { data: walletUSDMBalance } = useSWR<BigNumber>(
    [`StakeMST:walletUSDMBalance:${active}`, chainId, getContract(chainId, "USDM"), "balanceOf", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  const tokenBalances = [
    { header: NETWORK_METADATA[chainId].nativeCurrency.symbol, value: nativeBalance ? nativeBalance.toString() : "0" },
    { header: "MST", value: walletMSTBalance ? walletMSTBalance.toString() : "0" },
    { header: "USDM", value: walletUSDMBalance ? walletUSDMBalance.toString() : "0" }
  ];

  return (
    <>
      <DisplayWalletItems tokenBalances={tokenBalances} />
    </>
  )
}

function WalletItem({ header, value }) {  
    return (
      <div className="wallet-item">
        <div className="header">{header}</div>
        <div className="value">{formatAmount(value,18,2,true)}</div>
      </div>
    );
}

const DisplayWalletItems = ({ tokenBalances }) => {
  return (
    <div className="wallet-container">
      <FaWallet className="wallet-icon" />
      {tokenBalances.map((balance, index) => (
        <WalletItem key={index} header={balance.header} value={balance.value} />
      ))}
    </div>
  );
};

