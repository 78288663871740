import React, { useEffect, useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";

import "./Mint.scss";
import { getMintData } from "../../lib/yields/mint";
import Loader from "../../utils/Loader";
import { getUSDMData } from "../../lib/yields/yields";

const handleClick = (path) => {
  window.open(path, '_blank');
}

const YieldsCard = () => {
  const [mintData, setMintData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [USDMData, setUSDMData] = useState([]);
  const [ETHData, setETHMData] = useState([]);
  const [USDTData, setUSDTData] = useState([]);
  const [USDCData, setUSDCData] = useState([]);

  console.log(mintData, "MINT DATA")

  useEffect(() => {
    getMintData(setMintData, setIsLoading);
    getUSDMData(
      setUSDMData,
      setIsLoading,
      setETHMData,
      setUSDTData,
      setUSDCData
    );
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section id="mint">
      <div className="container">
        <div className="mx-3 my-5">
          <SingleYield
            title="Meridian Mint - USDM Yields"
            avg={
              mintData.reduce(
                (acc, item) => acc + parseInt(item.percentage),
                0
              ) / 3
            }
            data={mintData}
          />

        </div>
        <div className="row gy-4 row-cols-1 row-cols-md-2">
          <div className="col">
            <div className="wrapper">
              <SingleYield
                title="Meridian Lend - ETH Yields"
                data={ETHData}
                avg={
                  ETHData.reduce(
                    (acc, item) => acc + parseInt(item.percentage),
                    0
                  ) / ETHData.length
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="wrapper">
              <SingleYield
                title="Meridian Lend - USDT Yields"
                data={USDTData}
                avg={
                  USDTData.reduce(
                    (acc, item) => acc + parseInt(item.percentage),
                    0
                  ) / USDTData.length
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="wrapper">
              <SingleYield
                title="Meridian Lend -  USDC Yields"
                data={USDCData}
                avg={
                  USDCData.reduce(
                    (acc, item) => acc + parseInt(item.percentage),
                    0
                  ) / USDCData.length
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="wrapper">
              <SingleYield
                title="Meridian Lend - USDM Yields"
                data={USDMData}
                avg={
                  USDMData.reduce(
                    (acc, item) => acc + parseInt(item.percentage),
                    0
                  ) / USDMData.length
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SingleYield = ({ data, title, avg, appPath }) => {
  // Function to calculate scaled width based on percentage
  const calculateScaledWidth = (percentage) => {
    const value = parseFloat(percentage.trim().replace("%", "")); // Parse and remove '%' from percentage
    return `${(value / findMaxPercentage(data)) * 100}%`; // Scale the value based on the maximum percentage
  };

  // Function to find the maximum percentage in the data array
  const findMaxPercentage = (data) => {
    let maxPercentage = 0;
    data.forEach((item) => {
      const value = parseFloat(item.percentage.trim().replace("%", ""));
      if (value > maxPercentage) {
        maxPercentage = value;
      }
    });
    return maxPercentage;
  };

  return (
    <div className="card-wrapper">
      <div className="d-flex justify-content-between align-items-center">
        <div className="top-part">
          <h5>{title}</h5>
          <p>Average {Math.round(avg)}%</p>
        </div>
        <div>
          <button className="dots btn border-0">
            <HiOutlineDotsVertical />
          </button>
        </div>
      </div>
      <div className="coins">
        {data.map((item, index) => (
          <div key={index} className="coin d-flex align-items-center">
            <div className="coin-icon">
              <img src={item.icon} alt={item.subTitle} style={{ width: '30px', height: '30px' }} // Inline style to resize the image
              />
            </div>
            <div className="coin-details">
              <h6 className="m-0 text-nowrap">{item.title}</h6>
              <p className="m-0">{item.subTitle}</p>
            </div>
            <div className="bar">
              <div
                className="fill"
                style={{
                  backgroundColor: item.color,
                  width: calculateScaledWidth(item.percentage),
                }}
              ></div>
            </div>
            <div className="percentage">{item.percentage}</div>
          </div>
        ))}
      </div>
      {/*
      <button className="second w-100" onClick={() => handleClick(appPath)}>
        Launch App
      </button>
      */}
    </div>
  );
};

export default YieldsCard;
