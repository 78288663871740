import React, { useContext, useEffect, useState } from 'react';
import image1 from "../../img/Symmetric.png";
import { ToggleContext } from './ToggleContext';

const BaseTrade = () => {
  const [mstPriceSymmetric, setMSTPriceSymmetric] = useState(null);
  const [mstLiquiditySymmetric, setMSTLiquiditySymmetric] = useState(0);
  const [usdmLiquiditySymmetric, setUSDMLiquiditySymmetric] = useState(0);

  useEffect(() => {
    const getMSTSymmetricData = async () => {
      try {
        const response = await fetch("https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/mst_price_meter");

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setMSTPriceSymmetric(data); // Assuming your API returns a number for MST price
      } catch (error) {
        console.error('Error fetching MST data:', error);
        // Handle error state if needed
      }
    };

    getMSTSymmetricData();
  }, []); // Empty dependency array means this effect runs only once after initial render

  useEffect(() => {
    const getMSTLiquidityData = async () => {
      try {
        // Fetch liquidity data using another endpoint or from the same endpoint if it provides both price and liquidity
        const response = await fetch("https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/mst_liquidity_meter");

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setMSTLiquiditySymmetric(data); // Assuming your API returns liquidity data
      } catch (error) {
        console.error('Error fetching MST liquidity data:', error);
        // Handle error state if needed
      }
    };

    getMSTLiquidityData();
  }, []); // Empty dependency array means this effect runs only once after initial render

  const BaseUSDMData = [
    {
      img: image1,
      priceType: "USDM/wstMTRG",
      price: "Coming soon",
      para: "Coming soon",
      button: "",
      href: "https://app.symm.fi/#/meter/swap"
    },
  ];

  const BaseMSTData = [
    {
      img: image1,
      priceType: "MST/wstMTRG",
      price: "$" + (mstPriceSymmetric), // Format with comma and no decimal places
      para: "Current Liquidity: $" + (mstLiquiditySymmetric ? mstLiquiditySymmetric.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "..."),
      button: "",
      href: "https://app.symm.fi/#/meter/swap"
    },
  ];

  const { toggle, setToggle } = useContext(ToggleContext);

  return (
    <div className="main">
      <div className="switchbtn">
        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
          MST
        </label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            checked={toggle} // Set checked attribute based on toggle state
            onChange={() => setToggle(!toggle)} // Use onChange event to update toggle state
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
            USDM
          </label>
        </div>
      </div>

      {toggle === true ? (
        <div className="tradeCards">
          {BaseUSDMData.map((e, i) => (
            <div className="card" key={i}>
              <img src={e.img} alt="PIC" style={{ width: '200px', height: 'auto' }} />
              <p className="switchType">
                {e.switch} {e.priceType}
              </p>
              {e.button !== "" ? (
                <>
                  <h1>{e.price}</h1>
                  <p className="cardPara">{e.para}</p>
                  <a
                    href={e.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button>{e.button}</button>
                  </a>
                </>
              ) : (
                <>
                  <h1>{e.price}</h1>
                  <p className="cardPara">{e.para}</p>
                </>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="tradeCards">
          {BaseMSTData.map((e, i) => (
            <div className="card" key={i}>
              <img src={e.img} alt="PIC" style={{ width: '200px', height: 'auto' }} />
              <p className="switchType">
                {e.switch} {e.priceType}
              </p>
              {e.button !== "" ? (
                <>
                  <h1>{e.price}</h1>
                  <p className="cardPara">{e.para}</p>
                  <a
                    href={e.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button>{e.button}</button>
                  </a>
                </>
              ) : (
                <>
                  <h1>{e.price}</h1>
                  <p className="cardPara">{e.para}</p>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BaseTrade;
