import { BigNumber, utils } from 'ethers'

export async function fetchPrice(apiUrl,tokenName) {
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (tokenName in data) {
      const tokenPrice = utils.parseUnits(data[tokenName].usd.toString(), 18);
      return tokenPrice;
    } else {
      throw new Error(`Token ID "${tokenName}" not found in the API response.`);
    }
  } catch (error) {
    console.error("Error fetching price:", error.message);
    throw error; 
  }
}

// For a given rewards token this function calculates the 
// weekly change for the rewards per weighted stake from the staking contract (rewardsPerWeightedStake)
function calculateWeeklyChange(values, currentPtr) {
  // Validate input
  if (!Array.isArray(values) || values.length !== 8) {
    throw new Error('Invalid input array to calculateWeeklyChange ');
  }  
  // Calculate the index for the value 7 days ago
  const ptr = currentPtr.toNumber();
  const oneWeekAgoPtr = (ptr + 1) % 8;
  const currentValue = BigNumber.from(values[ptr].toString());
  const oneWeekAgoValue = BigNumber.from(values[oneWeekAgoPtr].toString());
  const weeklyChange = BigNumber.from(currentValue.sub(oneWeekAgoValue)); 
  return weeklyChange;
}

function calculateDailyChange(values, currentPtr) {
  // Validate input
  if (!Array.isArray(values) || values.length !== 8) {
    throw new Error('Invalid input array to calculateDailyChange ');
  }  
  // Calculate the index for the value 1 day ago
  const ptr = currentPtr.toNumber();
  const oneDayAgoPtr = (ptr + 7) % 8;
  const currentValue = BigNumber.from(values[ptr].toString());
  const oneDayAgoValue = BigNumber.from(values[oneDayAgoPtr].toString());
  const dailyChange = BigNumber.from(currentValue.sub(oneDayAgoValue)); 
  return dailyChange;
}


// Uses the change in rewardsPerWeightedStake for each rewards token to calculate the added USD per weighted stake
// These are then summed to get the total USD per weighted stake for all reward tokens
export function getWeeklyBaseApr(rewardTokens, snapshots, currentRateIndex, mstPrice, tokenPrices){
  if (!Array.isArray(rewardTokens) || rewardTokens.length === 0 || !Array.isArray(snapshots) || snapshots.length === 0) {
    throw new Error('Invalid input array to getBaseApr ',rewardTokens, snapshots);
  }  
  let weeklyUsdPerMST = BigNumber.from(0)
  for (let i = 0; i < rewardTokens.length; i++) {
    const weeklyChange = BigNumber.from(calculateWeeklyChange(snapshots[i], currentRateIndex))
    let tokenPrice =  tokenPrices[i] ? tokenPrices[i] : BigNumber.from(0);
    tokenPrice =  tokenPrices[i] ? BigNumber.from(tokenPrices[i].toString()) : BigNumber.from(0);    
    weeklyUsdPerMST = weeklyUsdPerMST.add(tokenPrice.mul(weeklyChange));

  }
  const powerScale = BigNumber.from('10').pow(12)
  let estimatedBaseApr =  BigNumber.from('0')
  if(mstPrice > 0){
    estimatedBaseApr = BigNumber.from(weeklyUsdPerMST.mul(52).mul(powerScale).div(mstPrice.toString()))
  }
  return estimatedBaseApr.gt(BigNumber.from("10000000000000000000")) ? BigNumber.from("10000000000000000000") : estimatedBaseApr
}

export function getDailyBaseApr(rewardTokens, snapshots, currentRateIndex, mstPrice, tokenPrices){
  if (!Array.isArray(rewardTokens) || rewardTokens.length === 0 || !Array.isArray(snapshots) || snapshots.length === 0) {
    throw new Error('Invalid input array to getBaseApr ',rewardTokens, snapshots);
  }  
  let dailyUsdPerMST = BigNumber.from(0)
  for (let i = 0; i < rewardTokens.length; i++) {
    const dailyChange = BigNumber.from(calculateDailyChange(snapshots[i], currentRateIndex))
    let tokenPrice =  tokenPrices[i] ? tokenPrices[i] : BigNumber.from(0);
    tokenPrice =  tokenPrices[i] ? BigNumber.from(tokenPrices[i].toString()) : BigNumber.from(0);    
    dailyUsdPerMST = dailyUsdPerMST.add(tokenPrice.mul(dailyChange));
  }
  const powerScale = BigNumber.from('10').pow(12)
  let estimatedBaseApr =  BigNumber.from('0')
  if(mstPrice > 0){
    estimatedBaseApr = BigNumber.from(dailyUsdPerMST.mul(365).mul(powerScale).div(mstPrice.toString()))
  }
  return estimatedBaseApr.gt(BigNumber.from("10000000000000000000")) ? BigNumber.from("10000000000000000000") : estimatedBaseApr
}


