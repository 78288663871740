import React from "react";
import "./LogoCard.scss";

const handleClick = (path) => {
  window.open(path, '_blank');
}

const LogoCard = () => {
  return (
    <section id="logo-card">
      <div className="container">
        <div className="gy-4 row row-cols-1 row-cols-md-2">
          {logoData.map((item) => (
            <div key={item.id} className="col">
              <div className="logo-card">
                <div className="tags d-flex justify-content-end">
                  {item.tags.map((tag) => (
                    <span className={`tag ${tag}`} key={tag}>
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="image d-flex justify-content-center">
                  <img className="logo-image" src={item.logo} alt={item.name} />
                </div>
                <h3 className="title">{item.name}</h3>
                <p className="tagline">{item.tagline}</p>
                <div className="description">
                  <p>
                    {item.description.map((desc, index) => (
                      <>
                        <span key={index}>{desc}</span> <br />
                      </>
                    ))}
                  </p>
                </div>
                <div className="buttons d-flex flex-column gap-md-3 gap-2">
                  <button className="first w-100" onClick={() => handleClick(item.tutorialPath)}>
                    Read/Watch Tutorial
                  </button>
                  <button className="second w-100" onClick={() => handleClick(item.AppPath)}>
                    Launch App
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LogoCard;

export const logoData = [
  {
    id: 1,
    logo: "/assets/exo-system/logo-lynx.svg",
    name: "Lynx",
    tagline: "Leverage Trade using MST ",
    description: [
      "Holders of MST can now open long/short positions with up to 100x leverage on BTC/USD, ETH/USD, and other tradeable instruments on the Fuse network.  Users can also provide MST liquidity to earn protocol fees.",
    ],
    tags: ["MST"],
    tutorialPath: "https://lynx-finance.gitbook.io/lynx-finance",
    AppPath: "https://www.lynx.finance/",
  },
  {
    id: 2,
    logo: "/assets/exo-system/logo-robinos.svg",
    name: "Robinos",
    tagline: "Bet on ‘Versus’ events",
    description: [
      "Robinos is a sports performance platform on the Telos EVM network. Use USDM to place bets on the short term events such as “which team will win”   Robinos lists a variety of different types of predictions for users to play with",
    ],
    tags: ["USDM"],
    tutorialPath: "https://robinospredict.notion.site/2dffcd940379462cbc626f6e7dae123e?v=22a28f25af564e9e8d60c8b85e52fad7",
    AppPath: "https://robinos.finance/",
  },
  {
    id: 3,
    logo: "/assets/exo-system/logo-swapsicle.svg",
    name: "Swapsicle",
    tagline: "Trade MST & USDM",
    description: [
      "Swapsicle is a cross-chain DeFi hub that offers a suite of services including Swaps, Yield Farming and concentrated liquidity.  Users can also provide MST and USDM liquidity to earn protocol fees.",
    ],
    tags: ["USDM", "MST"],
    tutorialPath: "https://docs.swapsicle.io/",
    AppPath: "https://www.swapsicle.io/",
  },
  {
    id: 4,
    logo: "/assets/exo-system/logo-swing.svg",
    name: "Swing",
    tagline: "Buy MST on every network",
    description: [
      "Swing is a modern crypto liquidity routing platform built for the financial future of blockchain.  It delivers frictionless, cross-chain liquidity for all crypto assets.",
    ],
    tags: ["MST"],
    tutorialPath: "https://swing.xyz/about",
    AppPath: "https://swing.xyz/",
  },
  {
    id: 5,
    logo: "/assets/exo-system/logo-symmetric.jpg",
    name: "Symmetric",
    tagline: "Trade MST & USDM",
    description: [
      "Symmetric is an Automated Market Maker and Decentralized Exchange where investors swap tokens and earn protocol fees by adding liquidity to smart pools for both USDM and MST",
    ],
    tags: ["USDM", "MST"],
    tutorialPath: "https://docs.symmetric.exchange/",
    AppPath: "https://app.symm.fi/#/",
  },
  {
    id: 6,
    logo: "/assets/exo-system/logo-voltage.jpg",
    name: "Voltage",
    tagline: "Trade MST & USDM",
    description: [
      "Voltage is a DeFi exchange on the Fuse Blockchain, enabling investors to trade USDM, MST and many other tokens. Users can also provide MST and USDM liquidity to earn protocol fees.."
    ],
    tags: ["USDM", "MST"],
    tutorialPath: "https://docs.voltage.finance/voltage",
    AppPath: "https://voltage.finance/swap",
  },

];
