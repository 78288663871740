import React from "react";
import TitleSection from "../../components/TitleSection/TitleSection";
import LogoCard from "../../components/Ecosystem/LogoCard/LogoCard";
import EcoFooter from "../../components/Ecosystem/Footer/EcoFooter";
import "./Ecosystem.css";

const Ecosystem = () => {
  return (
    <main className="default-container page-layout">
      <TitleSection title="Meridian Ecosystem" 
                    subTitle="Enabling the seamless integration of USDM and MST in collaboration with our trusted partners" 
                    shortSubTitle="Integrating with our trusted partners" />
      <LogoCard />
      <EcoFooter />
    </main>
  );
};

export default Ecosystem;
