import React, { useContext } from "react";
import image1 from "../../img/Swapsicle.png";
import image2 from "../../img/Symmetric.png";
import image3 from "../../img/meridian.png";
import { ToggleContext } from './ToggleContext';

const BaseTrade = () => {
  // State variables for USDM data
  const [usdmPriceSwapsicle1, setUSDMPriceSwapsicle1] = React.useState(0);
  const [usdmPriceSwapsicle2, setUSDMPriceSwapsicle2] = React.useState(0);
  const [usdmLiquiditySwapsicle1, setUSDMLiquiditySwapsicle1] = React.useState(0);
  const [usdmLiquiditySwapsicle2, setUSDMLiquiditySwapsicle2] = React.useState(0);
  const [usdmPriceSymmetric, setUSDMPriceSymmetric] = React.useState(0);
  const [usdmLiquiditySymmetric, setUSDMLiquiditySymmetric] = React.useState(0);
  const [usdmPriceNewDex1, setUSDMPriceNewDex1] = React.useState(0);
  const [usdmPriceNewDex2, setUSDMPriceNewDex2] = React.useState(0);
  const [usdmLiquidityNewDex1, setUSDMLiquidityNewDex1] = React.useState(0);
  const [usdmLiquidityNewDex2, setUSDMLiquidityNewDex2] = React.useState(0);

  // State variables for MST data
  const [mstPriceSwapsicle, setMSTPriceSwapsicle] = React.useState(0);
  const [mstPriceSwapsicle2, setMSTPriceSwapsicle2] = React.useState(0);
  const [mstLiquiditySwapsicle, setMSTLiquiditySwapsicle] = React.useState(0);
  const [mstLiquiditySwapsicle2, setMSTLiquiditySwapsicle2] = React.useState(0);
  const [mstPriceSymmetric, setMSTPriceSymmetric] = React.useState(0);
  const [mstLiquiditySymmetric, setMSTLiquiditySymmetric] = React.useState(0);
  const [mstPriceNewDex1, setMSTPriceNewDex1] = React.useState(0);
  const [mstPriceNewDex2, setMSTPriceNewDex2] = React.useState(0);
  const [mstLiquidityNewDex1, setMSTLiquidityNewDex1] = React.useState(0);
  const [mstLiquidityNewDex2, setMSTLiquidityNewDex2] = React.useState(0);

  // Format numbers with commas
  function formatNumberWithCommas(number) {
    return Math.floor(number).toLocaleString();
  }




  // Fetch USDM data
  React.useEffect(() => {
    const getUSDMData = async () => {
      try {
        const response = await fetch(
          "https://api.dexscreener.com/latest/dex/tokens/0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9"
        );
        const data = await response.json();

        // Dex IDs
        const swapsicleDexId = "swapsicle";
        const newDexDexId = "0x1f2542d8f784565d526eeadc9f1ca8fbb75e5996"; // Ensure lowercase

        // Filter pairs
        const swapsiclePairs = data.pairs.filter(
          (pair) => pair.dexId.toLowerCase() === swapsicleDexId
        );

        const newDexPairs = data.pairs.filter(
          (pair) => pair.dexId.toLowerCase() === newDexDexId
        );

        // Process Swapsicle pairs
        // USDM/WTLOS
        const usdmWtlosPairSwapsicle = swapsiclePairs.find(
          (pair) => pair.quoteToken.symbol === "WTLOS"
        );

        // USDM/USDC
        const usdmUsdcPairSwapsicle = swapsiclePairs.find(
          (pair) =>
            pair.quoteToken.symbol === "USDC" ||
            pair.quoteToken.symbol === "USDC.e"
        );

        if (usdmWtlosPairSwapsicle) {
          const price = usdmWtlosPairSwapsicle.priceUsd;
          const liquidity = usdmWtlosPairSwapsicle.liquidity.usd;
          setUSDMPriceSwapsicle1(price);
          setUSDMLiquiditySwapsicle1(formatNumberWithCommas(liquidity));
        } else {
          console.error("USDM/WTLOS pair not found on Swapsicle.");
        }

        if (usdmUsdcPairSwapsicle) {
          const price = usdmUsdcPairSwapsicle.priceUsd;
          const liquidity = usdmUsdcPairSwapsicle.liquidity.usd;
          setUSDMPriceSwapsicle2(price);
          setUSDMLiquiditySwapsicle2(formatNumberWithCommas(liquidity));
        } else {
          console.error("USDM/USDC pair not found on Swapsicle.");
        }

        // Process New Dex pairs
        // USDM/WTLOS
        const usdmWtlosPairNewDex = newDexPairs.find(
          (pair) => pair.quoteToken.symbol === "WTLOS"
        );

        // USDM/USDC or USDT
        const usdmUsdcPairNewDex = newDexPairs.find(
          (pair) =>
            pair.quoteToken.symbol === "USDC" ||
            pair.quoteToken.symbol === "USDT"
        );

        if (usdmWtlosPairNewDex) {
          const price = usdmWtlosPairNewDex.priceUsd;
          const liquidity = usdmWtlosPairNewDex.liquidity.usd;
          setUSDMPriceNewDex1(price);
          setUSDMLiquidityNewDex1(formatNumberWithCommas(liquidity));
        } else {
          console.error("USDM/WTLOS pair not found on the new dex.");
        }

        if (usdmUsdcPairNewDex) {
          const price = usdmUsdcPairNewDex.priceUsd;
          const liquidity = usdmUsdcPairNewDex.liquidity.usd;
          setUSDMPriceNewDex2(price);
          setUSDMLiquidityNewDex2(formatNumberWithCommas(liquidity));
        } else {
          console.error("USDM/USDC or USDT pair not found on the new dex.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getUSDMData();
  }, []);


  React.useEffect(() => {
    const getUSDMSymmetricData = async () => {
      try {
        const response = await fetch(
          "https://api.goldsky.com/api/public/project_clnbo3e3c16lj33xva5r2aqk7/subgraphs/symmetric-telos/prod/gn",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: `
                query USDMPoolQuery {
                  token(id: "0x8f7d64ea96d729ef24a0f30b4526d47b80d877b9") {
                    latestUSDPrice
                  }
                  pool(id: "0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b") {
                    id
                    symbol
                    totalLiquidity
                    totalLiquiditySansBPT
                  }
                }
              `,
            }),
          }
        );

        const responseData = await response.json();

        // Accessing the latestUSDPrice field from the token object
        const priceUSDM = responseData.data.token.latestUSDPrice;
        const roundedPriceUSDM = Math.round(priceUSDM * 10000) / 10000;

        const liquidityUSDM = responseData.data.pool.totalLiquidity;
        const formattedUSDMLiquidity = Math.floor(Number(liquidityUSDM)).toLocaleString(); // Remove decimal places and add comma as thousands separator

        setUSDMPriceSymmetric(roundedPriceUSDM);
        setUSDMLiquiditySymmetric(formattedUSDMLiquidity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getUSDMSymmetricData();
  }, []);

  // Fetch MST data
  React.useEffect(() => {
    const getMSTData = async () => {
      try {
        const response = await fetch(
          "https://api.dexscreener.com/latest/dex/tokens/0x568524DA340579887db50Ecf602Cd1BA8451b243"
        );
        const data = await response.json();

        // Dex IDs
        const swapsicleDexId = "swapsicle";
        const newDexDexId = "0x1f2542d8f784565d526eeadc9f1ca8fbb75e5996"; // Ensure lowercase

        // Filter pairs
        const swapsiclePairs = data.pairs.filter(
          (pair) => pair.dexId.toLowerCase() === swapsicleDexId
        );

        const newDexPairs = data.pairs.filter(
          (pair) => pair.dexId.toLowerCase() === newDexDexId
        );

        // Process Swapsicle pairs
        if (swapsiclePairs.length > 0) {
          // MST/WTLOS
          const mstWtlosPairSwapsicle = swapsiclePairs.find(
            (pair) => pair.quoteToken.symbol === "WTLOS"
          );

          // MST/USDC
          const mstUsdcPairSwapsicle = swapsiclePairs.find(
            (pair) =>
              pair.quoteToken.symbol === "USDC" || pair.quoteToken.symbol === "USDC.e"
          );

          if (mstWtlosPairSwapsicle) {
            const price = mstWtlosPairSwapsicle.priceUsd;
            const liquidity = mstWtlosPairSwapsicle.liquidity.usd;
            setMSTPriceSwapsicle(price);
            setMSTLiquiditySwapsicle(formatNumberWithCommas(liquidity));
          } else {
            console.error("MST/WTLOS pair not found on Swapsicle.");
          }

          if (mstUsdcPairSwapsicle) {
            const price = mstUsdcPairSwapsicle.priceUsd;
            const liquidity = mstUsdcPairSwapsicle.liquidity.usd;
            setMSTPriceSwapsicle2(price);
            setMSTLiquiditySwapsicle2(formatNumberWithCommas(liquidity));
          } else {
            console.error("MST/USDC pair not found on Swapsicle.");
          }
        } else {
          console.error("No Swapsicle pairs found for MST.");
        }

        // Process New Dex pairs
        if (newDexPairs.length > 0) {
          // MST/WTLOS or MST/STLOS
          const mstWtlosPairNewDex = newDexPairs.find(
            (pair) => pair.quoteToken.symbol === "WTLOS" || pair.quoteToken.symbol === "STLOS"
          );

          // MST/USDC or MST/USDT
          const mstUsdcPairNewDex = newDexPairs.find(
            (pair) =>
              pair.quoteToken.symbol === "USDC" || pair.quoteToken.symbol === "USDT"
          );

          if (mstWtlosPairNewDex) {
            const price = mstWtlosPairNewDex.priceUsd;
            const liquidity = mstWtlosPairNewDex.liquidity.usd;
            setMSTPriceNewDex1(price);
            setMSTLiquidityNewDex1(formatNumberWithCommas(liquidity));
          } else {
            console.error("MST/WTLOS or MST/STLOS pair not found on the new dex.");
          }

          if (mstUsdcPairNewDex) {
            const price = mstUsdcPairNewDex.priceUsd;
            const liquidity = mstUsdcPairNewDex.liquidity.usd;
            setMSTPriceNewDex2(price);
            setMSTLiquidityNewDex2(formatNumberWithCommas(liquidity));
          } else {
            console.error("MST/USDC or MST/USDT pair not found on the new dex.");
          }
        } else {
          console.error("No pairs found for MST on the new dex.");
        }
      } catch (error) {
        console.error("Error fetching MST data:", error);
      }
    };

    getMSTData();
  }, []);

  // Fetch MST data from Symmetric
  React.useEffect(() => {
    const getMSTSymmetricData = async () => {
      try {
        const response = await fetch("https://api.goldsky.com/api/public/project_clnbo3e3c16lj33xva5r2aqk7/subgraphs/symmetric-telos/prod/gn", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
            query MSTPoolQuery {
              token(id: "0x568524da340579887db50ecf602cd1ba8451b243") {
                latestUSDPrice
              }
              pool(id: "0x304970d2511aa3b121148afd387cfa623f551410000200000000000000000016") {
                totalLiquidity
              }
            }
            `
          })
        });

        const responseData = await response.json();

        // Accessing the latestUSDPrice field from the token object
        const priceMST = responseData.data.token.latestUSDPrice;
        const roundedPriceMST = Math.round(priceMST * 100) / 100; // Round to 2 decimal places

        const liquidityMST = responseData.data.pool.totalLiquidity;
        const formattedMSTLiquidity = Math.floor(Number(liquidityMST)).toLocaleString();

        setMSTPriceSymmetric(roundedPriceMST);
        setMSTLiquiditySymmetric(formattedMSTLiquidity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getMSTSymmetricData();
  }, []);

  // BaseUSDMData array
  const BaseUSDMData = [
    {
      img: image1,
      priceType: "USDM/WTLOS",
      price: "$" + usdmPriceSwapsicle1,
      para: "Current Liquidity: $" + usdmLiquiditySwapsicle1,
      button: "Trade Now",
      href: "https://telos.swapsicle.io/swap?outputCurrency=USDM_ADDRESS&inputCurrency=WTLOS_ADDRESS"
    },
    {
      img: image1,
      priceType: "USDM/USDC",
      price: "$" + usdmPriceSwapsicle2,
      para: "Current Liquidity: $" + usdmLiquiditySwapsicle2,
      button: "Trade Now",
      href: "https://telos.swapsicle.io/swap?outputCurrency=USDM_ADDRESS&inputCurrency=USDC_ADDRESS"
    },
    {
      img: image2,
      priceType: "USDM/USDT-USDC",
      price: "$" + usdmPriceSymmetric,
      para: "Current Liquidity: $" + usdmLiquiditySymmetric,
      button: "Trade Now",
      href: "https://telos.symm.fi/#/telos/swap"
    },
    {
      img: image3,
      priceType: "USDM/WTLOS",
      price: "$" + usdmPriceNewDex1,
      para: "Current Liquidity: $" + usdmLiquidityNewDex1,
      button: "Trade Now",
      href: "https://swaps.meridianfinance.net"
    },
    {
      img: image3,
      priceType: "USDM/USDT",
      price: "$" + usdmPriceNewDex2,
      para: "Current Liquidity: $" + usdmLiquidityNewDex2,
      button: "Trade Now",
      href: "https://swaps.meridianfinance.net"
    },
  ];

  // BaseMSTData array
  const BaseMSTData = [
    {
      img: image2,
      priceType: "MST/USDM",
      price: "$" + mstPriceSymmetric,
      para: "Current Liquidity: $" + mstLiquiditySymmetric,
      button: "Trade Now",
      href: "https://telos.symm.fi/#/telos/swap"
    },
    {
      img: image3,
      priceType: "MST/STLOS",
      price: "$" + mstPriceNewDex1,
      para: "Current Liquidity: $" + mstLiquidityNewDex1,
      button: "Trade Now",
      href: "https://swaps.meridianfinance.net"
    },
  ];

  const { toggle, setToggle } = useContext(ToggleContext);

  return (
    <div className="main">
      {/* Toggle Switch */}
      <div className="switchbtn">
        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
          MST
        </label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            checked={toggle}
            onChange={() => setToggle(!toggle)}
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
            USDM
          </label>
        </div>
      </div>

      {/* Display USDM Data */}
      {toggle === true ? (
        <div className="tradeCards">
          {BaseUSDMData.map((e, i) => (
            <div className="card" key={i}>
              <img src={e.img} alt="PIC" style={{ width: '200px', height: 'auto' }} />
              <p className="switchType">
                {e.priceType}
              </p>
              <h1>{e.price}</h1>
              <p className="cardPara">{e.para}</p>
              <a
                href={e.href}
                target="_blank"
                rel="noreferrer"
              >
                <button>{e.button}</button>
              </a>
            </div>
          ))}
        </div>
      ) : (
        // Display MST Data
        <div className="tradeCards">
          {BaseMSTData.map((e, i) => (
            <div className="card" key={i}>
              <img src={e.img} alt="PIC" style={{ width: '200px', height: 'auto' }} />
              <p className="switchType">
                {e.priceType}
              </p>
              <h1>{e.price}</h1>
              <p className="cardPara">{e.para}</p>
              <a
                href={e.href}
                target="_blank"
                rel="noreferrer"
              >
                <button>{e.button}</button>
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BaseTrade;
