import TitleSection from "../../components/TitleSection/TitleSection";
import YieldsCard from "../../components/Yields/Mint";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./yields.css";

const Yields = () => {
  return (
    <main className="default-container page-layout">      
      <TitleSection title="Meridian Yields" 
                    subTitle="Find the best investment yields across all Meridian products and across all our partner networks"
                    shortSubTitle ="Token yields across partner networks" />
      <YieldsCard /> 
      
    </main>
  );
};

export default Yields;
