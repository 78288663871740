import { ethers } from "ethers";
import { BASE_MAINNET, TELOS_TESTNET, TELOS_MAINNET, FUSE_MAINNET, METER_MAINNET, TAIKO_MAINNET } from "./chains";

const { AddressZero } = ethers.constants;

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  [BASE_MAINNET]: {
    DIA_ORACLE: "0x72301227B359df68637E962b3ED0B46575167277",
    MeridianMultiPoolStaking: "0xF3d840E84183AA5f4FaC81be97E09b1C4B199ffe",
    MeridianUSDMStaking: "0xfCcD02F7a964DE33032cb57746DC3B5F9319eaB7",
    MST: "0x2F3b1A07E3eFb1fCc64BD09b86bD0Fa885D93552",
    USDM: "0x5e06eA564efcB3158a85dBF0B9E017cb003ff56f",
    RewardReader: "0x69EcA412FFDe3F70A3AFeDFC2212c1c9Cfe2299B",
    StakedGmxTracker: "0xF4cd03207D6f590A9b001A91ccFcc81D95626B27",
    BonusGmxTracker: "0xc5c585F3abf1aE67A42Ef32ED3082ffdA971b99E",
    FeeGmxTracker: "0xc6F502B0A5079342d8e153441FeA9621bD27Be4D",
    StakedGlpTracker: "0x0dD255eb87087b7AfB8EE6074F91CFF8DD394e9F",
    FeeGlpTracker: "0xe677F9CC83c512770da2cC80a7CB8C79D89801E5",
  },
  [TELOS_TESTNET]: {
    // telos testnet
    DIA_ORACLE: "0x86c5997d10d1DF6cbB15c01280e596ED5ac90a33",
    MST: "0xa9a495086b8870c1F91Cd175A4d404912ea40325",
    NATIVE_TOKEN: "0xaE85Bf723A9e74d6c663dd226996AC1b8d075AA9", // Fill
    MeridianMultiPoolStaking: "0xeeD4a3c7d569690a609f04d109498E2FF8f449A3",
    USDMStaking: "0xE07D7f1C1153bCebc4f772C48A8A8eed1283ecCE",

  },

  [TELOS_MAINNET]: {
    // telos mainnet
    DIA_ORACLE: "0x86c5997d10d1DF6cbB15c01280e596ED5ac90a33",
    NATIVE_TOKEN: "0x4200000000000000000000000000000000000006", // Fill
    MST: "0x568524DA340579887db50Ecf602Cd1BA8451b243",
    USDM: "0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9",
    MSTMAIN: "0x568524DA340579887db50Ecf602Cd1BA8451b243",
    MeridianMultiPoolStaking: "0x493A60387522a7573082f0f27B98d78Ca8635e43",
    MeridianUSDMStaking: "0xE07D7f1C1153bCebc4f772C48A8A8eed1283ecCE",
    UniswapGmxEthPool: "0xad701515a9a3116E1313C6CfF5194e346F34a45E",
    Multicall: "0x96535E6ecF23Ba183248E0fC653361F5920F171D",
  },
  [TAIKO_MAINNET]: {
    // telos mainnet
    PRICE_FEED: "0xd79417be2cE02fc22F2E520869b1DfC02a677751",
    NATIVE_TOKEN: "0x4200000000000000000000000000000000000006", // Fill
    MST: "0x9cf571FD53a49993993D0BE98045EF8416bCF4F2",
    USDM: "0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9",
    MSTMAIN: "0x9cf571FD53a49993993D0BE98045EF8416bCF4F2",
    MeridianMultiPoolStaking: "0x623741035bf302DFe954828F08f626D0247864ac",
    MeridianUSDMStaking: "0xE07D7f1C1153bCebc4f772C48A8A8eed1283ecCE",
    UniswapGmxEthPool: "0xad701515a9a3116E1313C6CfF5194e346F34a45E",
    Multicall: "0x96535E6ecF23Ba183248E0fC653361F5920F171D",
  },

  [FUSE_MAINNET]: {
    // fuse mainnet
    DIA_ORACLE: "0x86c5997d10d1DF6cbB15c01280e596ED5ac90a33",
    PRICE_FEED: "0x9376DB040fE7Da43866471036c06E00bBA0Ce8EF",
    NATIVE_TOKEN: "0x0BE9e53fd7EDaC9F859882AfdDa116645287C629", // Fill
    MST: "0x2363Df84fDb7D4ee9d4E1A15c763BB6b7177eAEe",
    USDM: "0x4447863cddABbF2c3dAC826f042e03c91927A196",
    MeridianMultiPoolStaking: "0xb513fE4E2a3ed79bE6a7a936C7837f0294AFFEAd",
    UniswapGmxEthPool: "0xad701515a9a3116E1313C6CfF5194e346F34a45E",
    Multicall: "0x96535E6ecF23Ba183248E0fC653361F5920F171D",
  },

  [METER_MAINNET]: {
    // fuse mainnet
    METER_ORACLE: "0xB8a36aB2d679Eec5b031193114338458fE7a65c3",
    METER_TEST_ORACLE: "0x69D4873B452b3D87e79f4F56A315d5bfD188383C",  
    NATIVE_TOKEN: "0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3", // MTRG
    MST: "0x5647f6cc997e45b81d786bAa9ECd8A1ad40Ef25f",
    USDM: "0x0f7CCEb45Afc2dCD626d6Da01116183e428F7F7D", // Dummy USDM contract on METER until real USDM available
    MeridianMultiPoolStaking: "0xe50882F137e0E00C06E62634f8A2b25c9FC64971",
    UniswapGmxEthPool: "0xad701515a9a3116E1313C6CfF5194e346F34a45E",
    Multicall: "0x96535E6ecF23Ba183248E0fC653361F5920F171D",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
