import { ethers} from "ethers";
import { getContract } from "config/contracts";
import { getRpcUrl } from "config/chains";


export function getConnection(chainId, contractName, abi) {
    const ContractAddress = getContract(chainId, contractName);
    const RPC = getRpcUrl(chainId);
    const RPCAsString = RPC ? RPC.toString() : ""
    if (!RPCAsString) {
        throw new Error("RPC URL is not available");
    }
    const provider = new ethers.providers.StaticJsonRpcProvider(RPCAsString, chainId);
    const contract = new ethers.Contract(ContractAddress, abi, provider);
    return contract
}